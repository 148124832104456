<template>
    <div>
        <el-switch
            v-for="item of switchList"
            :key="item.prop"
            @change="switchClick($event, item)"
            :inactive-text="item.inactiveText"
            :value="item.value === '1' ? true : false">
        </el-switch>
        <!-- || switchValueList.indexOf(item.prop) !== -1 -->
    </div>
</template>
<script>
export default {
    name: 'multipleSwitchComp',
    props: {
        value: {
            type: String,
            default: ''
        },
        switchList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        switchValueList() {
            return this.value.split(',');
        }
    },
    mounted() {
        this.$emit('input', JSON.stringify(this.switchList));
    },
    methods: {
        switchClick(val, item) {
            if (val) {
                item.value = '1';
            } else {
                item.value = '0';
            }
            let switchValueListCpy = this.$_.cloneDeep(this.switchList);

            // if (val) {
            //     switchValueListCpy.push(prop);
            // } else {
            //     switchValueListCpy = switchValueListCpy.filter(item => item !== prop);
            // }
            this.$emit('input', JSON.stringify(switchValueListCpy));
        }
    }
};
</script>
<style scoped lang="less">
/deep/ .el-switch__label--left {
    color: #409EFF;
}
/deep/ .el-switch__label.is-active {
    color: #303133;
}
</style>
