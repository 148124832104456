<template>
  <div class="panelWrap">
    <div class="panelWrap-list">
      <div class="panelWrap-list_header">配置</div>
      <div
        class="panelWrap-list_wrap"
        style="position: relative"
        v-for="(panel, index) in panelList"
        :key="panel.pid"
        @click="changeActivePanel(panel.pId)"
        v-show="!panel.indicator"
      >
        <i
          class="el-icon-aim"
          style="position: absolute; left: 17px"
          v-show="activePanelPId === panel.pId"
        ></i>
        {{ `${index + 1} ${panel.panelConfigurations.schemaLabel}` }}
      </div>
    </div>
    <div class="panelWrap-config">
      <div class="panelWrap-config_title">{{ activePanel?.label }}</div>
      <wuxForm
        v-if="activePanel"
        ref="wuxForm"
        :schemas="activePanel?.schemas"
        v-model="activePanel.panelConfigurations"
        labelPosition="top"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    activePanelPId: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      show: true
    };
  },
  computed: {
    activePanel: {
      get() {
        return this.panelList.find(item => item.pId === this.activePanelPId);
      },
      set(val) {
        const theIndex = this.panelList.findIndex(item => (item.pId = val.pId));
        this.$set(this.panelList, theIndex, val);
      }
    },
    panelList: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    changeActivePanel(val) {
      this.$emit("updateActivePanelPId", val);
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style scoped lang="less">
.panelWrap {
  display: flex;
  background: #fff;

  .panelWrap-list {
    width: 168px;
    overflow-y: auto;

    .panelWrap-list_header {
      width: 100%;
      box-sizing: border-box;
      height: 48px;
      background-color: #f7f7f7;
      padding: 12px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
    }

    .panelWrap-list_wrap {
      box-sizing: border-box;
      height: 47px;
      line-height: 20px;
      padding: 12px 16px;
      font-size: 14px;
      color: #323233;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 0;
      box-shadow: none;
      border-bottom: 1px solid #e0e0e0;
      border-radius: 0;
    }
  }

  .panelWrap-config {
    flex: 1;
    width: 250px;
    padding: 0 5px;

    .panelWrap-config_title {
      margin-right: auto;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #323233;
      text-align: left;
      padding: 24px 16px;
      border-bottom: 1px solid #f2f4f6;
    }

    .panelWrap-config_wrap {
      padding: 12px 16px;

      .panelWrap-config_wrap_title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #323233;
        line-height: 24px;
        font-weight: 600;
      }

      .panelWrap-config_wrap_config {
        margin-top: 10px;
      }
    }
  }
}
</style>
