<template>
  <div v-if="column?.editable || rowData?.editable">
    <el-select v-model="rowData[column.prop]" placeholder="请选择">
      <el-option
        v-for="item in column.options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
  <div v-else>
    <div class="select" :style="getStyle(rowData[column.prop], column)">
      {{ getLabel(rowData[column.prop], column.options) }}
    </div>
  </div>
</template>
<script>
export default {
  name: "selectCom",
  props: {
    column: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tagType: "primary"
    };
  },
  computed: {
    rowData: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    // 根据下拉值 返回回显
    getLabel() {
      return function (value, options) {
        let labelName = "";

        options.forEach(el => {
          if (Number(el.value) === Number(value)) {
            labelName = el.label;
          }
        });

        return labelName;
      };
    },
    // 根据下拉值 返回样式回显
    // column.style为selectColor：即边框和字体颜色一致，背景色默认白色
    // column.style为selectBackgroundColor：即无边框，字体颜色默认白色
    getStyle() {
      return function (value, column) {
        let style = {};

        column.options.forEach(el => {
          if (Number(el.value) === Number(value)) {
            if (column.style === "selectColor") {
              style = { color: el.color, border: `1px solid ${el.color}` };
            } else if (column.style === "selectBackgroundColor") {
              style = { backgroundColor: el.color, color: "#FFFFFF" };
            } else {
              style = { fontSize: "14px", width: "100%", textAlign: "left" };
            }
          }
        });
        return style;
      };
    }
  },
  methods: {}
};
</script>
<style scoped lang="less">
.select {
  width: 60px;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
  text-align: center;
}
</style>
