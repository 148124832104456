<script>
import store from "@/store";

export default {
  data() {
    return {};
  },
  props: {
    // 选择公司页为true，其他页为false
    isCompanyNav: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSidebarNavCollapse() {
      return store.state.loginData.isSidebarNavCollapse;
    },
    userName() {
      return this.$store.state.loginData.userName || "用户";
    }
  },
  methods: {
    /**
     * 个人设置
     */
    toPersonalSettings() {
      let url = this.isCompanyNav?"personal-settings-without-router":"personal-settings-hasrouter";
      this.$router.push({ name: url});
    },
    /**
     * 退出登陆
     */
    logout() {
      this.$store.commit("loginData/logout");
    }
  }
};
</script>

<template>
  <el-popover trigger="hover" class="aside-bottom">
    <p @click="toPersonalSettings">
      <i class="el-icon-s-custom" /><span>个人设置</span>
    </p>
    <p><i class="el-icon-s-home" /><span>前往官网</span></p>
    <p @click="logout()">
      <i class="el-icon-switch-button" /><span>退出</span>
    </p>
    <div slot="reference" class="aside-bottom_user">
      <span class="aside-bottom_user_icon">{{ userName.slice(0, 2) }}</span>
    </div>
  </el-popover>
</template>

<style scoped lang="less">
.el-popover {
  font-size: 14px;

  p {
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    height: 36px;
    line-height: 36px;
    cursor: pointer;

    &:hover {
      color: #118dff;
      background: #e5edfe;
      border-radius: 5px;
    }

    span {
      margin-left: 15px;
    }
  }
}

.aside-bottom {
  width: max-content;
  padding: 27px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .aside-bottom_user {
    cursor: pointer;

    .aside-bottom_user_icon {
      width: 43px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      border-radius: 43px;
      color: #fff;
      background: #118dff;
      display: inline-block;
    }
  }

  .aside-bottom_user>*+* {
    margin-left: 12px;
  }
}</style>
