import { Loading } from "element-ui";
export default {
  namespaced: true,
  state: {
    // 当前 激活的 菜单 层级
    activeMenuLevel: [],
    // 原始 菜单 层级,
    originMenuLevel: [],
    // 当前 激活的 菜单 列表
    activeMenu: [],
    // 原始 菜单
    originMenu: [],
    // 菜单 是否 折叠
    isSidebarNavCollapse: false,
    // 真实token
    userToken: "",
    // 刷新token
    refreshToken: "",
    // 用户名
    userName: "",
    // 用户ID
    userId: "",
    // 用户手机号
    phone: "",
    // 选择的店铺
    basicShopInfo: {},
    // 进入门店(该门店信息)
    shopInfo: {},
    // 人脸状态
    faceStatus: false
  },
  getters: {},
  mutations: {
    activeMenuLevel(state, payload) {
      state.activeMenuLevel = payload;
    },
    originMenuLevel(state, payload) {
      state.originMenuLevel = payload;
    },
    activeMenu(state, payload) {
      state.activeMenu = payload;
    },
    originMenu(state, payload) {
      state.originMenu = payload;
    },
    isSidebarNavCollapse(state, payload) {
      state.isSidebarNavCollapse = payload;
    },
    userToken(state, payload) {
      state.userToken = payload;
    },
    refreshToken(state, payload) {
      state.refreshToken = payload;
    },
    userName(state, payload) {
      state.userName = payload;
    },
    userId(state, payload) {
      state.userId = payload;
    },
    basicShopInfo(state, payload) {
      state.basicShopInfo = payload;
    },
    shopInfo(state, payload) {
      state.shopInfo = payload;
    },
    phone(state, payload) {
      state.phone = payload;
    },
    faceStatus(state, payload) {
      state.faceStatus = payload;
    },
    logout(state) {
      state.userToken = "";
      state.refreshToken = "";
      state.phone = "";
      state.activeMenu = [];
      state.activeMenuLevel = [];
      state.originMenu = [];
      state.originMenuLevel = [];
      const loadingInstance = Loading.service({ fullscreen: true, lock: true });
      setTimeout(() => {
        loadingInstance.close();
        window.location.reload();
      }, 2000);
    },
    resetMenu(state) {
      state.activeMenu = [];
      state.activeMenuLevel = [];
      state.originMenu = [];
      state.originMenuLevel = [];
    }
  },
  actions: {}
};
