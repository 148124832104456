<template>
    <div class="account_ettings">
        <div class="ettings-item">
            <div class="ettings-item-left">
                <div>用户名</div>
                <div>{{ userInfo.name }}</div>
            </div>
            <div class="ettings-item-right">
                <el-button @click="clickFormBtn(0)">修改用户名</el-button>
            </div>
        </div>
        <div class="ettings-item">
            <div class="ettings-item-left">
                <div>手机号</div>
                <div>{{ userInfo.phone }}</div>
            </div>
            <div class="ettings-item-right">
                <el-button @click="clickFormBtn(1)">修改手机号</el-button>
            </div>
        </div>
        <div class="ettings-item">
            <div class="ettings-item-left">
                <div>
                    <i class="iconfont icon-icon_fysq"></i>
                    <span>头像</span>
                </div>
                <div>支持2M以内的JPG或PNG图片</div>
            </div>
            <div class="ettings-item-right">
                <el-upload :action="actionUrl" :headers="headers" :show-file-list="false"
                    :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess" :on-error="handleFileFail"
                    :on-remove="handleRemove">
                    <el-button @click="clickFormBtn(2)">修改头像</el-button>
                </el-upload>
            </div>
        </div>
        <div class="ettings-item">
            <div class="ettings-item-left">
                <div>密码</div>
                <div>设置独一无二的密码以保护你的账户</div>
            </div>
            <div class="ettings-item-right">
                <el-button @click="clickFormBtn(3)">修改密码</el-button>
            </div>
        </div>

        <div class="ettings-item" v-if="false">
            <div class="ettings-item-left">
                <div>
                    <i class="iconfont icon-weixin"></i>
                    <span>微信</span>
                </div>
                <div>
                    <span v-if="userInfo.wxUnionid">你已绑定微信，</span>
                    <span v-else>绑定微信后，</span>
                    可通过微信扫码登录数票票
                </div>
            </div>
            <div class="ettings-item-right">
                <el-button v-if="userInfo.wxUnionid" @click="unbindWx">解除绑定</el-button>
                <el-button v-else @click="clickFormBtn(4)">绑定微信</el-button>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-input v-if="index === 0 || index === 1" v-model="dialogValue" :placeholder="`请输入`"></el-input>
            <el-form v-if="index === 3" :model="pasForm" status-icon :rules="rules" ref="pasForm" label-width="100px"
                class="demo-pasForm">
                <el-form-item label="旧密码" prop="oldPass">
                    <el-input type="password" v-model="pasForm.oldPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input type="password" v-model="pasForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="pasForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!-- 绑定微信 -->
            <bindWechat @login="login" v-if="index === 4 && dialogVisible"></bindWechat>
            <span v-if="index !== 4" slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="index === 3" @click="resetPasForm">重置</el-button>
                <el-button type="primary" @click="submitBtn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getAccountInfoApi, updateAccountInfoApi } from '@/api/personal_settings/index.js';
import store from '@/store/index.js';
import bindWechat from "@/views/login/components/qrcode.vue";

export default {
    components: {
        bindWechat
    },
    data() {
        var validateOldPass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入旧密码'));
            } else {
                if (window.btoa(this.pasForm.oldPass)  != this.userInfo.password ) {
                    callback(new Error('旧密码输入错误，请重新输入'));
                    return
                }
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            }else if (!/^.{6,12}$/.test(value)) {
                callback(new Error('密码长度必须在6到12位之间'));
            }  else {
                if (this.pasForm.checkPass !== '') {
                    this.$refs.pasForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.pasForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            dialogValue: null,
            // title:'',
            index: '',
            userInfo: {},
            actionUrl: `${process.env.VUE_APP_API_URL}/minio-server/minio/upload/by/type?type=1`,
            headers: {
                Authorization: store.state.loginData.userToken
            },
            pasForm: {},
            rules: {
                oldPass: [
                    { validator: validateOldPass, trigger: 'blur' }
                ],
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        title() {
            let title = ''
            switch (this.index) {
                case 0:
                    title = '修改用户名'
                    break;
                case 1:
                    title = '修改手机号'
                    break;
                case 2:
                    title = '修改头像'
                    break;
                case 3:
                    title = '修改密码'
                    break;
                case 4:
                    title = '扫码完成绑定微信'
                    break;
                // case 5:
                // title = '解除绑定微信'
                // break;
                default:
                    break;
            }
            return title
        }
    },
    created() {
        this.getAccountInfoFn();
    },
    methods: {
        getAccountInfoFn() {
            getAccountInfoApi({ uid: this.$store.state.loginData.userId }).then(res => {
                console.log('用户信息', res);
                if (res.code === 1000) {
                    this.userInfo = res.data;
                    delete this.userInfo.createDate;
                    delete this.userInfo.updateDate;
                }
            })
        },
        clickFormBtn(index) {
            this.index = index;
            if (index !== 2) {
                this.dialogVisible = true;
            }
        },
        unbindWx() {
            this.$confirm('此操作将解除绑定微信, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data=this.$_.cloneDeep(this.userInfo);
                data.openId ='';
                data.wxUnionid ='';
                console.log('data', data);
                this.updateAccountInfoFn(data);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        resetPasForm() {
            this.$refs.pasForm.resetFields();
        },
        submitBtn() {
            let data
            switch (this.index) {
                case 0:
                    data = { ...this.userInfo, name: this.dialogValue }
                    break;
                case 1:
                    data = { ...this.userInfo, phone: this.dialogValue }
                    break;
                case 3:
                    console.log('this.userInfo.password', this.userInfo.password);
                    console.log('window.btoa(this.pasForm.oldPass', window.btoa(this.pasForm.oldPass));
                    if (window.btoa(this.pasForm.oldPass)  === this.userInfo.password) {
                        data = { ...this.userInfo, password: window.btoa(this.pasForm.checkPass) }
                        console.log(data);
                    } else {
                        this.$message({
                            type: "warning",
                            message: "旧密码错误，请重新输入"
                        });
                        return
                    }

                    break;
                default:
                    break;
            }
            this.updateAccountInfoFn(data);
        },
        updateAccountInfoFn(data) {
            updateAccountInfoApi(data).then(res => {
                console.log('更新用户信息', res);
                if (res.code === 1000) {
                    this.dialogVisible = false;
                    this.$message({
                        type: "success",
                        message: "修改成功"
                    });
                    this.getAccountInfoFn();
                }
            })
        },
        handleClose() {
            // this.$confirm('确认关闭？').then(() => {
            this.dialogVisible = false;
            // });
        },
        beforeAvatarUpload(file) {
            // 检查文件类型是否为 JPG 或 PNG  
            const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/png';

            // 检查文件大小是否小于 2MB  
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isValidFormat) {
                this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }

            // 返回验证结果  
            return isValidFormat && isLt2M;
        },
        handleAvatarSuccess(res) {
            console.log(`${process.env.VUE_APP_FILE_URL}/${res.data}`);
            this.updateAccountInfoFn({ ...this.userInfo, headImgUrl: `${process.env.VUE_APP_FILE_URL}/${res.data}` });
        },
        handleFileFail() {
            console.log(' 失败的回调');
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        }
    }

};
</script>
<style scoped lang="less">
@import "@/assets/css/main.less";

.account_ettings {
    .ettings-item {
        padding: 15px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #D8D8D8;

        .ettings-item-left {
            font-size: 14px;

            div:nth-child(1) {
                display: flex;
                align-items: center;

                .iconfont {
                    margin-right: 10px;
                    font-size: 30px;

                }

                .icon-weixin {
                    color: #50B674;
                }
            }

            div:nth-child(2) {
                margin-top: 8px;
                color: #999999;
            }
        }

        .el-button {
            padding: 0;
            width: 98px;
            height: 37px;
            text-align: center;
            line-height: 37px;
        }
    }
}

/deep/.el-upload--picture-card {
    width: 100%;
    min-height: 0;
    line-height: 0;
    border: 0;

    .el-upload {}
}
</style>
