<template>
  <div class="shop-management">
    <Nav class="layout-nav" :isCompanyNav="true" />
    <div class="contents">
      <div class="content">
        <div class="titles">
          <wuxForm
            v-model="searchData"
            :schemas="searchFields"
            :isShowSubmit="false"
            :inline="true"
          />
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-for="(v, i) in tabsList"
            :key="i"
            :label="v.label"
            :name="v.value"
          >
            <shopListCom
              v-if="shopList.length > 0"
              :list="shopList"
              :typeList="typeList"
              @change="handleChange"
            />
            <div v-else class="noData">暂无数据</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
// 商品列表
import shopListCom from "./components/shop_list.vue";
// 创建店铺弹框
import { getNowUserBasicShopListApi, shopListApi } from "@/api/company";

import Nav from "@/views/layout/nav/index.vue";
import { resetRouter } from "@/router";
import { updateRoutes } from "@/utils/routingAuthority";
// import { debounce } from "@/utils/utils";
import debounce from 'lodash.debounce';
import { getMenu } from "@/api/rbac";
export default {
  components: {
    Nav,
    shopListCom
  },
  data() {
    return {
      // 搜索
      searchFields: [
        {
          schemaType: "select",
          schemaLabel: "客户名称",
          schemaKey: "basicShopId",
          props: {
            options: []
          }
        },
        {
          schemaLabel: "门店名称",
          schemaKey: "name",
          schemaType: "text"
        }
      ],
      searchData: {
        basicShopId: "",
        name: ""
      },
      phone: null,
      shopName: null,
      activeName: "all",
      tabsList: [
        {
          label: "全部",
          value: "all"
        }
      ],
      // 组件使用 客户类型（不包含全部）
      typeList: [],
      basicShopList: [],
      // 客户列表
      shopList: []
    };
  },
  watch: {
    searchData: {
      handler() {
        this.shopGetPagFn()
      },
      deep: true
    }
  },

  created() {
    resetRouter();
    this.init();
  },
  methods: {
    init() {
      this.getNowUserBasicShopListFn();
    },
    async getNowUserBasicShopListFn() {
      let res = await getNowUserBasicShopListApi();
      let list = res.data.map(v => {
        return {
          label: v.companyName,
          value: v.id
        };
      });
      this.basicShopList = res.data;
      this.$set(this.searchFields[0].props, "options", list);
    },
    // 获取列表数据
    shopGetPagFn: debounce(function () {
      this.basicShopList.forEach(v => {
        if (v.id === this.searchData?.basicShopId) {
          this.$store.commit("loginData/basicShopInfo", v);
        }
      });
      shopListApi({ ...this.searchData }).then(({ data }) => {
        this.shopList = data.list;
      });
    }, 300),
    handleChange(val) {
      // 将店铺信息给 存起来 后面要什么给什么
      this.$store.commit("loginData/shopInfo", val);
      getMenu({ shopId: val.id }).then(({ data }) => {
        const { menu } = data;
        const realMenu = this.recursionMenu(menu);
        updateRoutes(realMenu);
        const loadingInstance = this.$loading({ fullscreen: true, lock: true });
        setTimeout(() => {
          loadingInstance.close();
          this.$router.push({ name: "workbench" });
        }, 2000);
      });
    },
    recursionMenu(menu) {
      return menu.map(item => {
        const { component, icon, name, realPath } = item;
        const realMenu = {
          path: realPath,
          name: component,
          meta: {
            name,
            icon
          }
        };
        if (item?.menuSubset && item.menuSubset.length > 0) {
          realMenu.children = this.recursionMenu(item.menuSubset);
        }

        return realMenu;
      });
    }
  }
};
</script>
<style scoped lang="less">
.layout-nav {
  padding: 0 200px 0;
}

.shop-management {
  height: 100%;

  .contents {
    width: auto;
    height: calc(100% - 80px);
    padding: 0 240px 0;
    background: url("@/assets/images/homepage/loginbg.png") no-repeat;
    background-size: cover;

    .content {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      width: 100%;
      height: 100%;
      background: #ffffff;

      .titles {
        padding-top: 30px;
        // padding: 10px 72px 0;
        // height: 100%;
        display: flex;
        justify-content: space-between;
        color: #333;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
      }

      .el-tabs {
        flex: 1;
        display: flex;
        flex-direction: column;

        /deep/.el-tabs__content {
          flex: 1;
        }
      }
    }
  }
}

/deep/#pane-all {
  height: 100% !important;

  .noData {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
