<template>
  <div class="previewWrap">
    <div
      v-for="(contract, idx) in contractList"
      :key="idx"
      :ref="`contract${contract.pageNo}`"
      class="previewWrap_safeArea"
    >
      <img :src="contract.uploadUrl" alt="合同图片" width="100%" @drop.prevent="drop($event, contract)" />
      <div v-if="idx === 0" style="width: max-content;height: max-content" @drop.prevent="ridingSeamSealWrapDrop($event, contract)">
        <ridingSeamSeal :style="{zIndex : isRidingSeamSeal ? 2 : -1}" class="ridingSeamSeal"
                        :width="100" :height="contract.height" />
      </div>
      <div
        v-for="panel in panelList.filter(
          panelInner => panelInner.pageNo === contract.pageNo
        )"
        :key="panel.pId"
        :class="{ isClick: activePanelPId === panel.pId }"
        :style="{
          position: 'absolute',
          left: `${panel.x}px`,
          top: `${panel.y}px`,
          opacity: panel.hidden ? 0 : 1,
          zIndex: (activePanelPId === panel.pId) ? 7 : 1
        }"
        draggable="true"
        @dragstart="dragStart($event, panel)"
        @dragend="dragEnd($event, panel)"
        @click="updateActivePanelPId(panel.pId)"
      >
        <i
          v-if="activePanelPId === panel.pId"
          class="el-icon-close previewWrap_safeArea_delete"
          @click="deletePanel(panel)"
        />
        <component
          :is="panel.name"
          v-model="panel.panelConfigurations"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { findAllVueComponents } from "../index.js";
import ridingSeamSeal from "./ridingSeamSeal.vue";

const components = findAllVueComponents();

const indicatorImage = new Image();
indicatorImage.src = require("@/assets/images/wux_contract/transparent.png");

export default {
  components: {
    ...components,
    ridingSeamSeal
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    wuxComponentsList: {
      type: Array,
      default: () => []
    },
    activePanelPId: {
      type: String
    },
    contractList: {
      type: Array,
      default: () => []
    },
    // 签署方数组
    signByOptions: {
      type: Array,
      default: () => []
    },
    // 是否是 骑缝章
    isRidingSeamSeal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 是否 在 区域 里
      isInWrap: false
    };
  },
  computed: {
    panelList: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    /**
     * 生成 一个 随机 ID
     * @returns {string} ID
     */
    generateRandomId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
    },
    /**
     * 组件 拖动 开始
     * @param {Object} event 原生拖动事件
     * @param {Object} panel 组件配置
     */
    dragStart(event, panel) {
      panel.hidden = true;
      event.dataTransfer.setDragImage(indicatorImage, 0, 0);
      event.dataTransfer.setData("text/plain", JSON.stringify({pId: panel.pId,name: panel.name }));
      event.dataTransfer.effectAllowed = "move";
      this.$emit("changeIndicatorStatus", true, panel);
    },
    /**
     * 组件 拖动 结束
     * @param {Object} event 原生拖动事件
     * @param {Object} panel 组件配置
     */
    dragEnd(event, panel) {
      this.$emit("changeIndicatorStatus", false, panel);
      this.$set(panel, "hidden", false);
    },
    /**
     * 放置 骑缝章
     * @param {Object} event 原生 drop 数据
     * @param {Object} contract 合同页面 信息
     */
    ridingSeamSealWrapDrop(event, contract) {
      this.drop(event, contract, true)
    },
    /**
     * 放置 拖动的 按钮 时 触发
     * @param {Object} event 原生 drop 数据
     * @param {Object} contract 合同页面 信息
     * @param {Boolean} isInRidingSeamSealWrap 是否 骑缝章 区域
     **/
    drop(event, contract, isInRidingSeamSealWrap = false ) {
      const eventData = JSON.parse(event.dataTransfer.getData("text/plain"));
      if ((eventData.name === "wuxRidingSeamSeal") && !isInRidingSeamSealWrap) return;
      const rect =
        this.$refs[`contract${contract.pageNo}`][0].getBoundingClientRect();
      const oldPanelIndex = this.panelList.findIndex(
        e => e?.pId === eventData?.pId
      );
      const newPanel = this.$_.cloneDeep({
        ...(oldPanelIndex !== -1
          ? this.panelList[oldPanelIndex]
          : {
            ...this.wuxComponentsList.find(e => e.name === eventData?.name),
            pId: this.generateRandomId()
          }),
        x: isInRidingSeamSealWrap ? (contract.width - 50) : (event.x - rect.x + 2),
        y: event.y - rect.top + 2,
        pageNo: contract.pageNo,
        hidden: false
      });
      if (!newPanel.name) return;
      oldPanelIndex !== -1
        ? this.$set(this.panelList, oldPanelIndex, newPanel)
        : this.panelList.push(
        newPanel.panelBeforeCreate
          ? newPanel.panelBeforeCreate.call(this, newPanel)
          : newPanel
      ) && this.updateActivePanelPId(newPanel.pId);
    },
    /**
     * 更新 选中的 组件 序号
     * @param {Number} val
     */
    updateActivePanelPId(val) {
      this.$emit("updateActivePanelPId", val);
    },
    /**
     * 删除组件
     * @param {Object} val
     */
    deletePanel(val) {
      this.$confirm("确认删除此组件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.panelList = this.panelList.filter(e => e.pId !== val.pId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>

<style scoped lang="less">
@import "@/assets/css/base";

img {
  display: block;
}

.previewWrap {
  flex: 1;
  overflow: auto;
  margin: 10px;
  box-sizing: border-box;

  .previewWrap_safeArea {
    width: max-content;
    margin-inline: auto;
    margin-bottom: 5px;
    overflow: visible;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    .ridingSeamSeal {
      width: 100px;
      position: absolute !important;
      right: -50px;
      top: 0;
    }

    .previewWrap_safeArea_delete {
      position: absolute;
      font-size: 14px;
      top: -7px;
      right: -7px;
      color: red;
      z-index: 1;
      cursor: pointer;
    }
  }

  .previewWrap_safeArea:last-child {
    margin-bottom: 0;
  }

  .previewWrap_safeArea:first-child {
    margin-top: 100px;
  }
}

.previewWrap::-webkit-scrollbar {
  width: 0 !important;
}

.isClick {
  border: 1px solid rgba(16, 99, 241, 0.7);
  box-sizing: border-box;
}
</style>
