<template>
  <div class="layout">
    <Nav class="layout-nav" />
    <div class="layout-warp">
      <Aside
        :class="[
          'layout-aside',
          { 'layout-aside_collapse': isSidebarNavCollapse }
        ]"
      />
      <Main class="layout-main" />
    </div>
    <faceDialog v-if="faceStatus" />
  </div>
</template>

<script>
import Aside from "./aside/index.vue";
import Nav from "./nav/index.vue";
import Main from "./main/index.vue";
import faceDialog  from "@/components/face_dialog/index.vue";
import store from "@/store";

export default {
    data() {
        return {}
    },
  components: {
    Aside,
    Nav,
    Main,
    faceDialog
  },
  computed: {
    faceStatus() {
      return store.state.loginData.faceStatus;
    },
    menus() {
      return store.state.loginData.menus;
    },
    isSidebarNavCollapse() {
      return store.state.loginData.isSidebarNavCollapse;
    }
  },
  methods: {
    
  }
};
</script>

<style scoped lang="less">
.layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;

  .layout-nav {
    width: 100%;
    height: 60px;
  }

  .qrcode-style {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 16px;
      color: red;
    }
    img {
      width: 190px;
      height: 190px;
      margin-top: 20px;
    }
    .code-box {
        width: 190px;
        height: 190px;
        margin-top: 20px;
        #qrcode {
            width: 100% !important;
            height: 100% !important;
        }
    }
  }

  .layout-warp {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
  }

  .layout-aside {
    width: 220px;
    flex-shrink: 0;
    box-shadow: 0 0 1px rgba(9, 30, 66, 0.12), 0 4px 8px rgba(9, 30, 66, 0.12);
  }

  .layout-aside_collapse {
    width: 66px !important;
  }

  .layout-main {
    min-width: 0;
    flex: 1;
    height: 100%;
  }
}
</style>
