// import {get, post} from '@/service/httpConfig';
import { axiosPost } from '@/utils/httpConfig';

// 分页查询店铺
export const getNowUserBasicShopListApi = data => {
    return axiosPost('/basis-server/basis/customer/shop/getNowUserBasicShopList', data);
};
// 分页查询店铺
export const shopListApi = data => {
    return axiosPost('/basis-server/basis/shop/getPage', data);
};
// 查询用户信息
export const getPersonInfoApi = data => {
    return axiosPost('/basis-server/basis/auth-server/auth/getPersonInfo/by/id', data);
};
// 计当前登陆人所有的店铺数量，并以类型区分
export const shopsNumApi = (data) => {
    return axiosPost('/basis-server/basis/shop/count/all/shops/num', data);
};
// 通过主键查询单条数据
export const getDetailApi = (data) => {
    return axiosPost('/basis-server/basis/shop/getById', data);
};
// （登录人）创建（自己）店铺
export const shopAddApi = (data) => {
    return axiosPost('/basis-server/basis/shop/add', data);
};
// （超管登录人）创建（他人）店铺
export const shopSuperAddApi = (data) => {
    return axiosPost('/basis-server/basis/shop/super/add', data);
};
