import { render, staticRenderFns } from "./collapse.vue?vue&type=template&id=21b43cb4&scoped=true"
import script from "./collapse.vue?vue&type=script&lang=js"
export * from "./collapse.vue?vue&type=script&lang=js"
import style0 from "./collapse.vue?vue&type=style&index=0&id=21b43cb4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b43cb4",
  null
  
)

export default component.exports