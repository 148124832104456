import "./index.less";
// 图片 上传 组件
import imageUploadComp from "./components/imageUploadComp.vue";
// 多选 开关 组件
import multipleSwitchComp from "./components/multipleSwitchComp.vue";
// 地图定位 组件
// import searchMapPosition from './components/search_map_position.vue';
import { Message } from "element-ui";

export default {
  name: "wuxForm",
  props: {
    // 相同 schemas 可以把 key 传过来, 以此 判断 dom 更新
    wuxFormKey: {
      type: [Number, String],
      default: 0
    },
    // v-model
    value: {
      type: Object,
      default: () => {}
    },
    // 表单规则
    schemas: {
      type: Array,
      default: () => []
    },
    // elForm label宽度
    labelWidth: {
      type: String,
      default: "90px"
    },
    // elForm label位置
    labelPosition: {
      type: String,
      default: "left"
    },
    // 是否 显示 提交按钮
    isShowSubmit: {
      type: Boolean,
      default: false
    },
    submitCol: {
      type: Number,
      default: 6
    },
    // 是否 是搜索 表单
    inline: {
      type: Boolean,
      default: false
    },
    // elementUI 组件尺寸
    size: {
      type: String,
      default: "small"
    }
  },
  data() {
    return {
      // 表单 规则
      rules: {},
      // 是否 显示 表单
      isShowWuxForm: true
    };
  },
  computed: {
    schemasAndWuxFormKey() {
      return { wuxFormKey: this.wuxFormKey, schemas: this.schemas };
    },
    // 表单 数据
    formData: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    schemasAndWuxFormKey: {
      handler() {
        this.resetWuxForm();
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 重置 表单
     * 通过 schemas 监听，如果 schemas 相同，你 可以 传递 wuxFormKey，或者 ref 手动触发 此函数
     */
    resetWuxForm() {
      this.initElForm();
    },
    /**
     * 表单 初始化
     */
    initElForm() {
      this.rules = {};
      // 初始化 formData
      // 如果 有 value, 则 双向绑定, 否则 初始化 formData
      if (Reflect.ownKeys(this.value).length === 0) {
        this.schemas.map(schema => {
          // defaultValue默认值
          // 当为checkGroup组件时（Checkbox 多选框），需要v-model默认值类型需设置为[]
          this.$set(
            this.formData,
            schema.schemaKey,
            schema.schemaDefaultValue != null
              ? schema.schemaDefaultValue
              : schema.schemaType === "checkGroup"
              ? []
              : ""
          );
        });
      }
      // 初始化 表单 规则
      // 如果有 schemaRequired 且 没有 自定义规则 schemaRules 则 添加 默认规则
      // 如果有 schemaRules 则 添加 指定的 schemaRules
      // 如果有 schemaValidator 则 添加 指定的 schemaValidator
      this.schemas.map(schema => {
        if (schema.schemaValidator) {
          this.$set(this.rules, schema.schemaKey, [
            {
              validator: schema.schemaValidator,
              trigger: ["blur", "change", "input"]
            }
          ]);
        } else if (schema.schemaRules) {
          this.$set(this.rules, schema.schemaKey, schema.schemaRules);
        } else {
          this.$set(this.rules, schema.schemaKey, [
            {
              required: Boolean(schema.schemaRequired),
              message: `请输入${schema.schemaLabel}`,
              trigger: ["blur", "change"]
            }
          ]);
        }
      });
    },
    /**
     * 渲染 组件
     * @param {*} schema
     * @returns
     */
    // eslint-disable-next-line complexity
    renderVNode(schema) {
      const listeners = schema.eventList
        ? Object.entries(schema.eventList)
        : [];

      // event 只会 传递 一个 自定义 参数， 或 被 组件 自带 事件 占用
      // 自定义 组件 的方法 如果 触发 传递 多个 参数 会 被 覆盖
      let events = Object.fromEntries(
        listeners.map(([eventName, eventHandler]) => [
          eventName,
          event =>
            eventHandler(
              event,
              schema.schemaKey,
              this.formData,
              this.schemas,
              this.rules
            )
        ])
      );

      let vNode = null;

      switch (schema.schemaType) {
        case "text":
          vNode = (
            <el-input
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              placeholder={
                schema?.props?.placeholder || `请输入${schema?.schemaLabel}`
              }
              maxlength={schema?.props?.maxlength}
              rows={schema?.props?.rows}
              showWordLimit={schema?.props?.showWordLimit}
              props={schema.props}
            />
          );
          break;
        case "number":
          vNode = (
            <el-input-number
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "autocomplete":
          vNode = (
            <el-autocomplete
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "datePicker":
          vNode = (
            <el-date-picker
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "progress":
          vNode = (
            <el-progress
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "upload":
          vNode = (
            <imageUploadComp
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "multipleSwitch":
          vNode = (
            <multipleSwitchComp
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "colorPicker":
          vNode = (
            <el-color-picker
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        case "switch":
          vNode = (
            <el-switch
              v-model={this.formData[schema.schemaKey]}
              size={this.size}
              {...{ on: events }}
              props={schema.props}
            />
          );
          break;
        // case 'searchMapPosition':
        //     vNode = <searchMapPosition v-model={this.formData[schema.schemaKey]} size={this.size} {...{on: events}}
        //         props={schema.props}/>;
        //     break;
        default:
          break;
      }
      if (schema.slot) {
        vNode = (
          <div>
            {this.$scopedSlots[schema.slot]({
              schema,
              formData: this.formData
            })}
          </div>
        );
      }
      return (
        <el-form-item
          v-show={!schema.hidden}
          label={schema.schemaLabel}
          prop={schema.schemaKey}
          key={schema.schemaKey}
        >
          {vNode}
          {/* 是否 在下方 显示 文本 */}
          {schema.schemaRemarkText && (
            <div class="remark-style">{schema.schemaRemarkText}</div>
          )}
        </el-form-item>
      );
    },
    /**
     * 行内 标题 分割
     * @param schema
     * @returns {JSX.Element}
     */
    renderDivider(schema) {
      return (
        <el-divider size={this.size} props={schema.props}>
          {schema.dividerContent}
        </el-divider>
      );
    },
    /**
     * elSelect 比较特殊, 单独渲染
     * @param {*} schema
     * @returns
     */
    renderSelectInput(schema) {
      const listeners = schema.eventList
        ? Object.entries(schema.eventList)
        : [];

      // event 只会 传递 一个 自定义 参数， 或 被 组件 自带 事件 占用
      // 自定义 组件 的方法 如果 触发 传递 多个 参数 会 被 覆盖
      let events = Object.fromEntries(
        listeners.map(([eventName, eventHandler]) => [
          eventName,
          event =>
            eventHandler(
              event,
              schema.schemaKey,
              this.formData,
              this.schemas,
              this.rules
            )
        ])
      );

      return (
        <el-form-item
          v-show={!schema.hidden}
          label={schema.schemaLabel}
          prop={schema.schemaKey}
          key={schema.schemaKey}
        >
          <el-select
            size={this.size}
            v-model={this.formData[schema.schemaKey]}
            disabled={schema.props.disabled}
            placeholder={schema.props.placeholder}
            {...{ on: events }}
          >
            {schema.props.options.map((option, index) => (
              <el-option
                key={option.key || index}
                label={option[schema.optionLabel || "label"]}
                value={option[schema.optionValue || "value"]}
                disabled={option.disabled}
              />
            ))}
          </el-select>
          {/* 是否 在下方 显示文本 */}
          {schema.schemaRemarkText && (
            <div class="remark-style">{schema.schemaRemarkText}</div>
          )}
        </el-form-item>
      );
    },
    /**
     * elCheckGroup 比较特殊，单独渲染
     * @param {*} schema
     * @returns
     */
    renderCheckboxGroup(schema) {
      return (
        <el-form-item
          v-show={!schema.hidden}
          label={schema.schemaLabel}
          prop={schema.schemaKey}
          key={schema.schemaKey}
        >
          <el-checkbox-group
            size={this.size}
            v-model={this.formData[schema.schemaKey]}
            placeholder={schema.props.placeholder}
          >
            {schema.props.options.map((option, index) => (
              <el-checkbox key={option.value || index} label={option.value}>
                {option.label}
              </el-checkbox>
            ))}
          </el-checkbox-group>
          {/* 是否 在下方 显示文本 */}
          {schema.schemaRemarkText && (
            <div class="remark-style">{schema.schemaRemarkText}</div>
          )}
        </el-form-item>
      );
    },
   /**
     * elRadioGroup 比较特殊，单独渲染
     * @param {*} schema
     * @returns
     */
    renderRadioGroup(schema) {
      const listeners = schema.eventList
      ? Object.entries(schema.eventList)
      : [];

    // event 只会 传递 一个 自定义 参数， 或 被 组件 自带 事件 占用
    // 自定义 组件 的方法 如果 触发 传递 多个 参数 会 被 覆盖
    let events = Object.fromEntries(
      listeners.map(([eventName, eventHandler]) => [
        eventName,
        event =>
          eventHandler(
            event,
            schema.schemaKey,
            this.formData,
            this.schemas,
            this.rules
          )
      ])
    );
     return (
       <el-form-item
         v-show={!schema.hidden}
         label={schema.schemaLabel}
         prop={schema.schemaKey}
         key={schema.schemaKey}
       >
         <el-radio-group
           size={this.size}
           v-model={this.formData[schema.schemaKey]}
           placeholder={schema.props.placeholder}
           {...{ on: events }}
         >
           {schema.props.options.map((option, index) => (
             <el-radio key={option.value || index} label={option.value}>
               {option.label}
             </el-radio>
           ))}
         </el-radio-group>
         {/* 是否 在下方 显示文本 */}
         {schema.schemaRemarkText && (
           <div class="remark-style">{schema.schemaRemarkText}</div>
         )}
       </el-form-item>
     );
   },
    /**
     * 提交JSX
     * @returns {JSX.Element}
     */
    renderSubmit() {
      return (
        <el-col span={this.submitCol}>
          <el-form-item>
            <el-button
              type="primary"
              size={this.size}
              onClick={() => this.submitForm()}
            >
              {this.inline ? "查询" : "提交"}
            </el-button>
            <el-button size={this.size} onClick={() => this.resetForm()}>
              重置
            </el-button>
          </el-form-item>
        </el-col>
      );
    },
    // 获取 表单验证 数据
    getValidateData() {
      return new Promise(resolve => {
        this.$refs.elForm.validate(valid => {
          if (valid) {
            return resolve(this.formData);
          }
          return Message.error({
            message: "请确认表单填写完整！"
          });
        });
      });
    },
    // 提交表单
    submitForm() {
      if (Object.keys(this.rules).length > 0) {
        return this.$refs.elForm.validate(valid => {
          if (valid) {
            return this.$emit("submit", this.formData);
          }
          return false;
        });
      }
      return this.$emit("submit", this.formData);
    },
    // 重置表单
    resetForm() {
      this.$refs.elForm.resetFields();
      this.$emit("submit", this.formData);
    }
  },
  render() {
    return (
      this.isShowWuxForm && (
        <el-form
          ref="elForm"
          rules={this.rules}
          inline={this.inline}
          labelWidth={this.labelWidth}
          label-position={this.labelPosition}
          props={{ model: this.formData }}
        >
          <el-row gutter={5}>
            {this.schemas.map(schema => {
              switch (schema.schemaType) {
                case "select":
                  return (
                    <el-col span={schema.span || (this.inline ? 6 : 24)}>
                      {this.renderSelectInput(schema)}
                    </el-col>
                  );
                case "divider":
                  return (
                    <el-col span={schema.span || (this.inline ? 6 : 24)}>
                      {this.renderDivider(schema)}
                    </el-col>
                  );
                case "checkGroup":
                  return (
                    <el-col span={schema.span || (this.inline ? 6 : 24)}>
                      {this.renderCheckboxGroup(schema)}
                    </el-col>
                  );
                  case "radioGroup":
                    return (
                      <el-col span={schema.span || (this.inline ? 6 : 24)}>
                        {this.renderRadioGroup(schema)}
                      </el-col>
                    );
                default:
                  return (
                    <el-col span={schema.span || (this.inline ? 6 : 24)}>
                      {this.renderVNode(schema)}
                    </el-col>
                  );
              }
            })}
            {this.isShowSubmit && this.renderSubmit()}
          </el-row>
        </el-form>
      )
    );
  }
};
