<template>
  <div class="basic-header">
    <div class="router-name border">
      {{ routerName }}
      <el-divider direction="vertical"></el-divider>
    </div>
    <el-tabs v-if="tabs.length" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="(v, i) in tabs"
        :key="i"
        :label="v.label"
        :name="v.name"
      ></el-tab-pane>
    </el-tabs>
    <div v-else style="flex: 1" class="border"></div>
    <div v-if="createText" @click="handleCreate" class="btn-style">
      {{ createText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "customHeader",
  props: {
    routerName: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      default: () => []
    },
    createText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: "1"
    };
  },
  methods: {
    // handleClick(tab, event) {
    handleClick() {
      this.$emit("handleClick", this.activeName);
    },
    handleCreate() {
      this.$emit("create");
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-tabs__nav-wrap::after {
  height: 0;
}
/deep/ .el-tabs__header.is-top {
  margin-bottom: 0 !important;
}
/deep/ .el-tabs__nav-scroll {
  height: 44px;
}
/deep/ .el-tabs__item {
  height: 44px;
  line-height: 44px;
}
.basic-header {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #e4e7ed;
  // padding: 5px 0;
  .router-name {
    height: 42px;
    padding: 0 24px;
    line-height: 42px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    .el-divider--vertical {
      margin: 0 4px 0 20px;
      height: 1.2em;
    }
  }
  .btn-style {
    color: #118dff;
    margin-right: 24px;
    padding: 9px 15px;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
  }
}
/deep/ .el-tabs {
  position: relative !important;
  flex: 1;
  width: 100%;
}

/deep/ .el-tabs__content {
  position: absolute !important;
  left: -140px !important;
  width: calc(100% + 150px) !important;
}
</style>
