// import {get, post} from '@/service/httpConfig';
import { axiosFormRequest, axiosGet, axiosPost } from "@/utils/httpConfig";

// 店铺 管理
// 获取客户列表
export const getCustomerPageApi = data => {
  return axiosPost("/basis-server/basis/customer/shop/getCustomerPage", data);
};
// 激活店铺
export const initCustomerShopApi = data => {
  return axiosPost("/basis-server/basis/customer/shop/initCustomerShop", data);
};
// 修改店铺
export const updateCustomerShopApi = data => {
  return axiosPost(
    "/basis-server/basis/customer/shop/updateCustomerShop",
    data
  );
};
// 删除店铺
export const deleteCustomerShopApi = data => {
  return axiosPost(
    "/basis-server/basis/customer/shop/deleteCustomerShop",
    data
  );
};
// 门店 管理
// 获取门店列表
export const getMoreShopApi = data => {
  return axiosPost("/basis-server/basis/shop/getPage/more/shop", data);
};
// （超管登录人）创建（他人）门店
export const shopSuperAddApi = data => {
  return axiosPost("/basis-server/basis/shop/super/add", data);
};
// 查询单个店铺详情
export const shopInfoApi = data => {
  return axiosPost("/basis-server/basis/shop/getById", data);
};
// 修改门店
export const shopEditApi = data => {
  return axiosPost("/basis-server/basis/shop/updateById", data);
};
// 删除门店
export const shopDelApi = data => {
  return axiosPost("/basis-server/basis/shop/deleteById", data);
};

// 获取合同模板分页信息
export const getTemplatePage = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/getTemplatePage",
    data,
    { timeout: 60000 }
  );
};

// 获取合同模板详情
export const getContractTemplateInfo = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/getContractTemplateInfo",
    data
  );
};

export const uploadTemplateFile = data => {
  return axiosFormRequest(
    "/basis-server/basis/contract/template/uploadTemplateFile",
    data,
    { timeout: 60000 }
  );
};

// 保存合同模板
export const saveTemplate = data => {
  return axiosPost("/basis-server/basis/contract/template/saveTemplate", data);
};

// 修改合同模板
export const updateTemplate = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/updateTemplate",
    data
  );
};
// 删除合同模板
export const deleteTemplatePage = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/deleteTemplatePage",
    data
  );
};

// 分页查询所有数据
export const getContractPage = data => {
  return axiosPost("/basis-server/basis/contract/getContractPage", data);
};

// 生成合同
export const generateContract = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/generateContract",
    data,
    { timeout: 60000 }
  );
};

export const deleteContract = data => {
  return axiosPost("/basis-server/basis/contract/deleteContract", data, {
    timeout: 60000
  });
};

export const getContractQrCode = data => {
  return axiosGet("/basis-server/basis/contract/getContractQrCode", data, {
    timeout: 60000
  });
};

export const getFilePageInfo = data => {
  return axiosPost(
    "/basis-server/basis/contract/template/getFilePageInfo",
    data,
    {
      timeout: 60000
    }
  );
};

export const templateGroupGetGroupList = data => {
  return axiosPost("/basis-server/basis/template/group/getGroupList", data);
};

// 激活码接口
// 批量生成激活码(去重)
export const batchAddCode = data => {
  return axiosPost("/basis-server/basis/code/add", data);
};
// 导出激活码文件
export const exportActivationFile = data => {
  return axiosPost("/basis-server/basis/code/exportActivationFile", data, {
    responseType: "blob"
  });
};
// 获取分页数据
export const pageCode = data => {
  return axiosPost("/basis-server/basis/code/getPageCode", data);
};
// 校验激活码
export const checkCode = data => {
  return axiosPost("/basis-server/basis/code/checkCode", data);
};


// 申请信息管理
// 获取分页数据
export const applyInfoPage = data => {
  return axiosPost("/basis-server/basis/applyInfo/getApplyInfoPage", data);
};
// 修改申请信息
export const updateApplyInfo = data => {
  return axiosPost("/basis-server/basis/applyInfo/updateApplyInfo", data);
};

// 在线直接激活（不需要激活码）
export const addApplyInfo = data => {
  return axiosPost("/basis-server/basis/applyInfo/addApplyInfo", data);
};