import axios from "axios";
import store from "@/store";
import { Message, Loading } from "element-ui";

let instance = axios.create({
  timeout: 5000,
  baseURL: process.env.VUE_APP_API_URL,
  validateStatus(status) {
    switch (status) {
      case 401:
        // 重置 token 路由
        Message.warning({ message: "授权失败，请重新登录" });
        // return store.commit('LOG_OUT');
        store.commit("loginData/logout");
        break;
      case 404:
        Message.warning({ message: "请求错误,未找到该资源" });
        break;
      default:
        !(status >= 200 && status < 300) &&
          Message.warning({ message: "服务端错误" });
    }
    return status >= 200 && status < 300;
  }
});

let activeRequests = 0;
let elLoadingService = null;

const startLoading = () => {
  if (activeRequests === 0) {
    elLoadingService = Loading.service({});
  }
  activeRequests++;
};

const endLoading = () => {
  activeRequests--;
  if (activeRequests === 0) {
    elLoadingService.close();
  }
};

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    if (config.loading) {
      startLoading();
    }
    if (store?.state?.loginData?.userToken) {
      config.headers.Authorization = store.state.loginData.userToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器即异常处理
instance.interceptors.response.use(
  response => {
    if (response?.config?.loading) {
      endLoading();
    }
    if (response?.data?.code !== 1000) {
      Message.error(response?.data?.message || "服务端错误");
      return Promise.reject(
        new Error(response?.data?.message || response?.data?.msg || "Error")
      );
    }
    return response?.data;
  },
  error => {
    if (error?.config?.loading) {
      endLoading();
    }
    if (!axios.isCancel(error)) {
      Message.error(error?.message || "服务端错误");
      return Promise.reject(error);
    }
  }
);

const axiosCustomFunctionHandler = (method, url, data, otherConfigs) => {
  return new Promise((resolve, reject) => {
    instance({
      method,
      url,
      [method === "get" || method === "delete" ? "params" : "data"]: data,
      ...otherConfigs
    }).then(resolve, reject);
  });
};

// GET 请求封装
export const axiosGet = (url, data = {}, otherConfigs = {}) =>
  axiosCustomFunctionHandler("get", url, data, otherConfigs);
export const axiosPost = (url, data = {}, otherConfigs = {}) =>
  axiosCustomFunctionHandler("post", url, data, otherConfigs);
export const axiosDelete = (url, data = {}, otherConfigs = {}) =>
  axiosCustomFunctionHandler("delete", url, data, otherConfigs);
export const axiosFormRequest = (url, data = {}, otherConfigs = {}) =>
  axiosCustomFunctionHandler("post", url, data, {
    ...otherConfigs,
    headers: { "Content-Type": "multipart/form-data" }
  });
export const axiosMultipartRequest = (url, data = {}, otherConfigs = {}) =>
  axiosCustomFunctionHandler("post", url, data, {
    ...otherConfigs,
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });
