<script>
import componentWrap from "./components/componentWrap.vue";
import previewWrap from "./components/previewWrap.vue";
import configWrap from "./components/configWrap.vue";
import { findAllVueComponents } from "./index.js";
import {
  getContractTemplateInfo,
  updateTemplate,
  getFilePageInfo
} from "@/api/custom";

const components = findAllVueComponents();
export default {
  components: {
    componentWrap,
    previewWrap,
    configWrap,
    ...components
  },
  data() {
    return {
      // wux组件列表
      wuxComponentsList: [],
      // 使用的组件列表
      panelList: [],
      // 编辑中的组件
      activePanelPId: "",
      // 指示器显隐
      isShowIndicator: false,
      // 指示器位置
      indicatorX: 0,
      indicatorY: 0,
      // 正拖拽的组件配置
      draggingPanel: {},
      configData: {},
      showVisible: false,
      routeParams: {},
      contractList: [],
      isRidingSeamSeal: false
    };
  },
  created() {
    this.getAllWuxConfigs();
    this.routeParams = this.$route.params;
    this.$route.params &&
      getContractTemplateInfo({ ...this.$route.params })
        .then(
          res => {
            res.data.configData &&
              (this.panelList = JSON.parse(res.data.configData).data);
            this.configData = res.data;
          },
          () => {
            this.$router.back();
          }
        )
        .then(() => {
          getFilePageInfo({ ...this.$route.params }).then(res => {
            this.contractList = res.data.list;
            this.showVisible = true;
          });
        });
  },
  methods: {
    /**
     * 拖动中 更新指示器位置
     * @param event
     */
    dragOver(event) {
      const { x, y } = this.$refs.wuxContract.getBoundingClientRect();
      this.indicatorX = event.clientX - x;
      this.indicatorY = event.clientY - y;
    },
    /**
     * 更改指示器状态
     * @param {Boolean} val 是否显示
     * @param {Object} panel 组件配置
     */
    changeIndicatorStatus(val, panel = {}) {
      this.draggingPanel = this.$_.cloneDeep(panel);
      this.isShowIndicator = val;
      this.isRidingSeamSeal = (panel.name === 'wuxRidingSeamSeal') && val
    },
    /**
     * 获取所有wux组件config
     */
    getAllWuxConfigs() {
      // 获取所有满足条件的文件夹路径
      const folders = require.context(
        "./wux_components",
        true,
        /^\.\/wux_.+\/config\.js$/
      );

      // 遍历每个文件夹路径，获取config.js
      folders.keys().forEach((key, index) => {
        const config = folders(key).default || folders(key);
        this.wuxComponentsList.push({ ...config, id: index });
      });
    },
    saveTemplate() {
      const config = {
        data: this.panelList.map(item => {
          return item.panelDestroyed
            ? item.panelDestroyed.call(this, item)
            : item;
        })
      };
      updateTemplate({
        ...this.configData,
        configData: JSON.stringify(config)
      }).then(() => {
        this.$message("保存成功");
        this.$router.back();
      });
    }
  }
};
</script>

<template>
  <div
    ref="wuxContract"
    class="wuxContract"
    @dragover.prevent="dragOver"
    v-if="showVisible"
  >
    <div style="position: absolute">
      <span @click="saveTemplate">保存</span>
      <span
        style="margin-left: 30px"
        @click="
          () => {
            this.$router.back();
          }
        "
        >取消</span
      >
    </div>
    <componentWrap
      :wuxComponentsList="wuxComponentsList"
      @changeIndicatorStatus="changeIndicatorStatus"
    />
    <previewWrap
      ref="previewWrap"
      v-model="panelList"
      :wuxComponentsList="wuxComponentsList"
      :activePanelPId="activePanelPId"
      :contractList="contractList"
      :signByOptions="routeParams.signByOptions"
      :isRidingSeamSeal="isRidingSeamSeal"
      @changeIndicatorStatus="changeIndicatorStatus"
      @updateActivePanelPId="
        val => {
          activePanelPId = val;
        }
      "
    />
    <configWrap
      v-model="panelList"
      :activePanelPId="activePanelPId"
      @updateActivePanelPId="
        val => {
          activePanelPId = val;
        }
      "
    />
    <component
      v-if="isShowIndicator"
      v-model="draggingPanel.panelConfigurations"
      :style="{
        position: 'absolute',
        top: `${indicatorY + 2}px`,
        left: `${indicatorX + 2}px`,
        zIndex: 3
      }"
      :is="draggingPanel.name"
      :isIndicator="true"
    />
  </div>
</template>

<style scoped lang="less">
@import "@/assets/css/base";

.wuxContract {
  display: flex;
  height: 100%;
  position: relative;
  background-color: @color-gray;
}
</style>
