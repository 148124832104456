<template>
  <div style="position: relative">
    <div class="content" :style="{width:`${width}px`,height: `${height}px`}">
      <span class="ridingSeamSealHit">骑缝章</span>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  background: aliceblue;
  border: 1px dashed #409EFF;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .ridingSeamSealHit {
    writing-mode: tb;
    line-height: 100px;
    letter-spacing: 30px;
  }
}
</style>
