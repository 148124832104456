var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showVisible)?_c('div',{ref:"wuxContract",staticClass:"wuxContract",on:{"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)}}},[_c('div',{staticStyle:{"position":"absolute"}},[_c('span',{on:{"click":_vm.saveTemplate}},[_vm._v("保存")]),_c('span',{staticStyle:{"margin-left":"30px"},on:{"click":() => {
          this.$router.back();
        }}},[_vm._v("取消")])]),_c('componentWrap',{attrs:{"wuxComponentsList":_vm.wuxComponentsList},on:{"changeIndicatorStatus":_vm.changeIndicatorStatus}}),_c('previewWrap',{ref:"previewWrap",attrs:{"wuxComponentsList":_vm.wuxComponentsList,"activePanelPId":_vm.activePanelPId,"contractList":_vm.contractList,"signByOptions":_vm.routeParams.signByOptions,"isRidingSeamSeal":_vm.isRidingSeamSeal},on:{"changeIndicatorStatus":_vm.changeIndicatorStatus,"updateActivePanelPId":val => {
        _vm.activePanelPId = val;
      }},model:{value:(_vm.panelList),callback:function ($$v) {_vm.panelList=$$v},expression:"panelList"}}),_c('configWrap',{attrs:{"activePanelPId":_vm.activePanelPId},on:{"updateActivePanelPId":val => {
        _vm.activePanelPId = val;
      }},model:{value:(_vm.panelList),callback:function ($$v) {_vm.panelList=$$v},expression:"panelList"}}),(_vm.isShowIndicator)?_c(_vm.draggingPanel.name,{tag:"component",style:({
      position: 'absolute',
      top: `${_vm.indicatorY + 2}px`,
      left: `${_vm.indicatorX + 2}px`,
      zIndex: 3
    }),attrs:{"isIndicator":true},model:{value:(_vm.draggingPanel.panelConfigurations),callback:function ($$v) {_vm.$set(_vm.draggingPanel, "panelConfigurations", $$v)},expression:"draggingPanel.panelConfigurations"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }