import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/login/index.vue";
import CompanyView from "@/views/company/index.vue";
import personalSettingsView from "@/views/personal_settings/index.vue";
import wuxContract from "@/views/contract/index.vue";
import store from "@/store/index";
import { faceStatus, getRPAStatus } from "@/api/invoicing";
import { updateRoutes } from "@/utils/routingAuthority";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/company",
    name: "company",
    component: CompanyView
  },
  {
    path: "/personal-settings-without-router",
    name: "personal-settings-without-router",
    component: personalSettingsView
  },
  {
    path: "/wux-contract",
    name: "wux-contract",
    component: wuxContract
  }
];

const router = new VueRouter({
  routes
});

const needTaxLogin = ["invoice-single", "invoice-review", "invoice-records"];

router.beforeEach((to, from, next) => {
  if (store.state.loginData.userToken || to.name === "login") {
    if (!store.state.loginData.faceStatus && needTaxLogin.includes(to.name)) {
      getRPAStatus({ shopId: store.state.loginData.shopInfo.id })
        .then(res => {
          if (res.code === 1000 && res.data === 'SUCCESS') {
            Vue.set(store.state.loginData, "faceStatus", false);
          } else {
            Vue.set(store.state.loginData, "faceStatus", true);
          }
        })
        .catch(() => {
          Vue.set(store.state.loginData, "faceStatus", true);
        });
    }
    next();
  } else {
    next("/login");
  }
});

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err);
};

const resetRouter = () => {
  router.matcher = new VueRouter({
    routes
  }).matcher;
};

if (
  store?.state?.loginData?.originMenu &&
  store.state.loginData.originMenu.length > 0
) {
  updateRoutes(store.state.loginData.originMenu, router);
}

export default router;

export { resetRouter };
