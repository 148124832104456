<template>
  <div class="container">
    <div class="mid-box">
      <div class="box-left" />
      <div class="box-right">
        <div class="logo">
          <img src="../../assets/images/login/icon.png" alt="数票票" />
        </div>
        <div class="title"></div>
        <el-tabs v-model="activeName" :stretch="true">
          <el-tab-pane
            v-for="(v, i) in tabs"
            :key="i"
            :label="v.label"
            :name="v.name"
          />
        </el-tabs>
        <qrcode class="login" @login="login" v-if="activeName === '1'" />
        <account class="login" @login="login" v-show="activeName === '2'" />
      </div>
    </div>
    <div class="bottom-box">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >蜀ICP备2024051917号</a
      >
      <span>成都数票票科技有限公司&nbsp;版权所有</span>
    </div>
  </div>
</template>
<script>
import account from "./components/account.vue";
import qrcode from "./components/qrcode.vue";
import { oauthToken } from "@/api/login";
export default {
  name: "loginView",
  components: {
    account,
    qrcode
  },
  data() {
    return {
      activeName: "1",
      tabs: [
        { label: "微信登录", name: "1" },
        { label: "账号登录", name: "2" }
      ]
    };
  },
  methods: {
    login({ wechatToken = "", phone = "", password = "" } = {}) {
      const params = {
        token: wechatToken,
        client_id: "pc",
        client_secret: 222222,
        scope: "all",
        grant_type: "pc",
        phone: phone,
        passWord: password
      };
      if (this.activeName === "1") {
        delete params.phone;
        delete params.passWord;
      }
      oauthToken(params).then(({ data }) => {
        const token = `${data?.tokenType} ${data?.value}`;
        const refreshToken = data?.refreshToken.value;
        this.$store.commit("loginData/userToken", token);
        this.$store.commit(
          "loginData/userName",
          data?.additionalInformation?.userName.slice(0, 4)
        );
        this.$store.commit("loginData/userId", data?.additionalInformation?.id);
        this.$store.commit("loginData/phone", phone);
        this.$store.commit("loginData/refreshToken", refreshToken);
        this.$router.push({ name: "company" });
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__header {
  width: 414px;
}

/deep/ .el-tabs__item {
  width: 67px;
  height: 57px;
  padding: 0 70px !important;
  line-height: 57px;
  font-size: 16px;
  text-align: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/images/homepage/loginbg.png") no-repeat;
  background-size: cover;

  .mid-box {
    width: 1200px;
    height: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.09);
    background-color: #fff;

    .box-left {
      flex: 1;
      height: 100%;
      background: #409eff;
      background: url("@/assets/images/login/login_bg.png") no-repeat;
      background-size: contain;
      background-position: center;
    }

    .box-right {
      width: 414px;
      height: 100%;
      padding: 40px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #ffffff;

      .logo {
        width: 100%;
        height: 60px;
        // font-size: 1.8em;
        // line-height: 40px;
        // text-align: center;
        // font-weight: bolder;
        display: flex;
        justify-content: center;
        img {
          width: 150px;
          height: auto;
          display: block;
        }
      }

      .title {
        font-size: 0.9em;
        color: #999999;
        margin-bottom: 20px;
      }

      .login {
        flex-grow: 1;
        width: 100%;
        height: 180px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .bottom-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: #f3f6fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #999;
    a {
      color: #999;
      text-decoration: inherit;
      margin-bottom: 5px;
    }
  }
}
</style>
