// 不要在此写入vuex数据，去modules里新建
import Vue from 'vue';
import Vuex from 'vuex';

// 全局 vuex 通用数据
import commonData from './modules/commonData';
// 登陆 vuex 数据
import loginData from './modules/loginData';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        commonData: commonData,
        loginData: loginData
    },
    plugins: [
        (theStore) => {
            let saveTimeout;
            // 从localStorage加载数据
            const savedData = localStorage.getItem('vuexData');

            if (savedData) {
                theStore.replaceState(JSON.parse(savedData));
            }

            // 监听 store mutation 触发 每秒 缓存
            theStore.subscribe(() => {
                clearTimeout(saveTimeout);
                saveTimeout = setTimeout(() => {
                    localStorage.setItem('vuexData', JSON.stringify(theStore.state));
                }, 1000); // 设置一个延迟时间，避免频繁保存
            });
        }
    ]
});

export default store;
