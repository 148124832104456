var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"previewWrap"},_vm._l((_vm.contractList),function(contract,idx){return _c('div',{key:idx,ref:`contract${contract.pageNo}`,refInFor:true,staticClass:"previewWrap_safeArea"},[_c('img',{attrs:{"src":contract.uploadUrl,"alt":"合同图片","width":"100%"},on:{"drop":function($event){$event.preventDefault();return _vm.drop($event, contract)}}}),(idx === 0)?_c('div',{staticStyle:{"width":"max-content","height":"max-content"},on:{"drop":function($event){$event.preventDefault();return _vm.ridingSeamSealWrapDrop($event, contract)}}},[_c('ridingSeamSeal',{staticClass:"ridingSeamSeal",style:({zIndex : _vm.isRidingSeamSeal ? 2 : -1}),attrs:{"width":100,"height":contract.height}})],1):_vm._e(),_vm._l((_vm.panelList.filter(
        panelInner => panelInner.pageNo === contract.pageNo
      )),function(panel){return _c('div',{key:panel.pId,class:{ isClick: _vm.activePanelPId === panel.pId },style:({
        position: 'absolute',
        left: `${panel.x}px`,
        top: `${panel.y}px`,
        opacity: panel.hidden ? 0 : 1,
        zIndex: (_vm.activePanelPId === panel.pId) ? 7 : 1
      }),attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.dragStart($event, panel)},"dragend":function($event){return _vm.dragEnd($event, panel)},"click":function($event){return _vm.updateActivePanelPId(panel.pId)}}},[(_vm.activePanelPId === panel.pId)?_c('i',{staticClass:"el-icon-close previewWrap_safeArea_delete",on:{"click":function($event){return _vm.deletePanel(panel)}}}):_vm._e(),_c(panel.name,{tag:"component",model:{value:(panel.panelConfigurations),callback:function ($$v) {_vm.$set(panel, "panelConfigurations", $$v)},expression:"panel.panelConfigurations"}})],1)})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }