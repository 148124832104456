<template>
  <div class="aside-menu">
    <div
      v-for="(menu, index) in typeListData"
      :key="menu.name"
      :class="[
        { 'aside-menu_element_active': menuIndex === index },
        'aside-menu_element'
      ]"
      @click="clickMenu(index)"
    >
      <span class="aside-menu_element_name">{{ menu?.name }}</span>
    </div>
    <div v-if="showBank" class="aside-menu_element" @click="backMenu()">
      <i class="el-icon-back aside-menu_element_icon" />
      <span class="aside-menu_element_name">返回</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuIndex: 0,
      typeListData: [
        { name: "账户设置" }
        // { name: '店铺管理' },
        // { name: '消息通知' }
      ]
    };
  },
  props: {
    showBank: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickMenu(i) {
      this.$emit("change", i);
      this.menuIndex = i;
    },
    backMenu() {
      this.$router.back();
    }
  }
};
</script>
<style scoped lang="less">
@import "@/assets/css/main.less";

// 菜单
.aside-menu {
  width: 121px;
  /* 当内容溢出时显示滚动条 */
  overflow-y: auto;
  padding: 8px;
  box-sizing: border-box;
  border-right: 1px solid #e4e7ed;

  .aside-menu_element {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background: #f0f5fc;
      color: #202d40;
      border-radius: 8px;

      .aside-menu_element_hover {
        display: block;
      }
    }

    .aside-menu_element_icon {
      font-size: 20px;
      color: #202d40;
      margin: 2px 16px 2px 2px;
    }

    .aside-menu_element_name {
      font-size: 14px;
      color: #666f80;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: 1px;
    }

    .aside-menu_element_hover {
      font-size: 20px;
      display: none;
      position: absolute;
      right: 16px;
    }
  }

  .aside-menu_element_active {
    color: #118dff !important;
    background-color: #e5edfe !important;
    border-radius: 8px !important;

    .aside-menu_element_icon {
      color: #118dff;
    }

    .aside-menu_element_name {
      color: #118dff !important;
    }
  }

  .aside-menu_element_active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    width: 3px;
    height: calc(100% - 24px);
    background: #176de6;
    border-radius: 0 2px 2px 0;
  }
}
</style>
