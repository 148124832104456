/**
 * aa_aa to aaAa
 * @param {String} underlineStr
 * @returns {String}
 */
const underlineToCamel = underlineStr => {
  return underlineStr.replace(/_([a-zA-Z])/g, function (match, p1) {
    return p1.toUpperCase();
  });
};

/**
 * Find all Vue components in './wux_components'
 * Folder specifications apply:
 * ./wux_components/The name of the component/index.vue
 * @returns {Object}
 */
const findAllVueComponents = () => {
  const requireComponent = require.context(
    "./wux_components",
    true,
    /^\.\/[\w-]+\/index\.vue$/
  );
  // Create an empty object to store the component
  const components = {};

  // Iterate through all component folders
  requireComponent.keys().forEach(fileName => {
    // Get the component configuration
    const componentConfig = requireComponent(fileName);

    // Obtain the component name
    const componentName = fileName
      .replace(/^\.\//, "")
      .replace(/\/index\.vue$/, "");

    // Add the component to the components object
    components[underlineToCamel(componentName)] =
      componentConfig.default || componentConfig;
  });

  return components;
};

export { underlineToCamel, findAllVueComponents };
