<script>
import store from "@/store";

export default {
  computed: {
    isSidebarNavCollapse() {
      return store.state.loginData.isSidebarNavCollapse;
    },
    activeRouteName() {
      return this.$route.name;
    },
    activeMenuLevel: {
      get() {
        return this.$store.state.loginData.activeMenuLevel;
      },
      set(val) {
        this.$store.commit("loginData/activeMenuLevel", val);
      }
    },
    originMenuLevel: {
      get() {
        return this.$store.state.loginData.originMenuLevel;
      },
      set(val) {
        this.$store.commit("loginData/originMenuLevel", val);
      }
    },
    activeMenu: {
      get() {
        return this.$store.state.loginData.activeMenu;
      },
      set(val) {
        this.$store.commit("loginData/activeMenu", val);
      }
    },
    originMenu: {
      get() {
        return this.$store.state.loginData.originMenu;
      },
      set(val) {
        this.$store.commit("loginData/originMenu", val);
      }
    }
  },
  created() {
    this.initMenu();
  },
  methods: {
    /**
     * 初始化 菜单
     */
    initMenu() {
      if (this.originMenuLevel.length === 0) {
        this.activeMenu = this.$_.cloneDeep(this.originMenu);
        return;
      }
      this.activeMenu = this.findMenu(this.originMenuLevel);
      this.activeMenuLevel = this.$_.cloneDeep(this.originMenuLevel);
    },
    /**
     * 菜单 点击
     * @param {Object} menu 菜单元素
     * @param {Number} index 序号
     * @returns {*}
     */
    clickMenu(menu, index) {
      if (menu.children) {
        this.activeMenuLevel = [...this.activeMenuLevel, index];
        this.activeMenu = menu.children;
        return;
      }
      this.originMenuLevel = this.$_.cloneDeep(this.activeMenuLevel);
      this.$router.push({ name: menu.name });
    },
    /**
     * 菜单 返回
     */
    backMenu() {
      this.activeMenuLevel = this.activeMenuLevel.slice(0, -1);
      this.activeMenu = this.activeMenuLevel.length
        ? this.findMenu(this.activeMenuLevel)
        : this.$_.cloneDeep(this.originMenu);
    },
    /**
     * 根据 序号 查找 菜单
     * @param {Array} indexes 序号数组
     * @returns {Array} 菜单数组
     */
    findMenu(indexes = []) {
      let currentObj = this.$_.cloneDeep(this.originMenu);

      for (let i = 0; i < indexes.length; i++) {
        currentObj = currentObj[indexes[i]].children;
      }
      return currentObj;
    }
  }
};
</script>

<template>
  <div :class="['aside-menu', { 'aside-menu_collapse': isSidebarNavCollapse }]">
    <div
      v-for="(menu, index) in activeMenu"
      :key="menu.name"
      :class="[
        { 'aside-menu_element_active': activeRouteName === menu.name },
        { 'aside-menu_element_top': menu.top },
        { 'aside-menu_element_top_collapse': menu.top && isSidebarNavCollapse },
        'aside-menu_element'
      ]"
      @click="clickMenu(menu, index)"
    >
      <i
        :class="['iconfont', menu.meta.icon, 'aside-menu_element_icon']"
        v-if="!isSidebarNavCollapse"
      />

      <el-tooltip
        class="aside-menu_element_name"
        v-if="isSidebarNavCollapse"
        effect="dark"
        :content="menu?.meta?.name"
        placement="right"
      >
        <span v-if="!menu.top">{{ menu?.meta?.name?.split("")[0] }}</span>
        <i
          v-if="menu.top"
          :class="['iconfont', menu.meta.icon, 'aside-menu_element_icon']"
        />
      </el-tooltip>

      <span class="aside-menu_element_name" v-if="!isSidebarNavCollapse">{{
        menu?.meta?.name
      }}</span>

      <i
        class="el-icon-right aside-menu_element_hover"
        v-if="!menu.top && menu.children && !isSidebarNavCollapse"
      />
    </div>
    <div
      class="aside-menu_element"
      v-if="activeMenuLevel.length > 0"
      @click="backMenu()"
    >
      <i class="el-icon-back aside-menu_element_icon" />
      <span class="aside-menu_element_name" v-if="!isSidebarNavCollapse"
        >返回</span
      >
    </div>
  </div>
</template>

<style scoped lang="less">
// 菜单
.aside-menu {
  width: 100%;
  /* 使用剩余空间，高度自适应 */
  flex-grow: 1;
  /* 当内容溢出时显示滚动条 */
  overflow-y: auto;
  padding: 8px;
  box-sizing: border-box;

  .aside-menu_element {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background: #f0f5fc;
      color: #202d40;
      border-radius: 8px;

      .aside-menu_element_hover {
        display: block;
      }
    }

    .aside-menu_element_icon {
      font-size: 20px;
      color: #202d40;
      margin: 2px 16px 2px 2px;
    }

    .aside-menu_element_name {
      width: calc(100% - 20px - 2px - 20px - 32px);
      font-size: 14px;
      color: #666f80;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .aside-menu_element_hover {
      font-size: 20px;
      display: none;
      position: absolute;
      right: 16px;
    }
  }

  .aside-menu_element_top {
    width: max-content !important;
    margin: 10px auto;
    flex-direction: column !important;
    justify-content: center !important;

    &:before {
      display: none !important;
    }

    .aside-menu_element_icon {
      font-size: 34px !important;
      margin: 0 auto !important;
    }

    .aside-menu_element_name {
      width: 80px !important;
      text-align: center !important;
    }
  }
  .aside-menu_element_top_collapse {
    padding: 0 !important;
  }

  .aside-menu_element_active {
    color: #118dff !important;
    background-color: #e5edfe !important;
    border-radius: 8px !important;

    .aside-menu_element_icon {
      color: #118dff;
    }

    .aside-menu_element_name {
      color: #118dff !important;
    }
  }

  .aside-menu_element_active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    width: 3px;
    height: calc(100% - 24px);
    background: #176de6;
    border-radius: 0 2px 2px 0;
  }
}

.aside-menu::-webkit-scrollbar {
  display: none;
}

.aside-menu_collapse {
  .aside-menu_element {
    width: 50px !important;
    height: 50px !important;
    text-align: center !important;

    .aside-menu_element_icon {
      margin: 0 !important;
    }

    .aside-menu_element_name {
      width: 100% !important;
    }
  }
}
</style>
