import { axiosPost, axiosGet } from "@/utils/httpConfig";

// 单张开票
export const invoiceOnce = data => {
  return axiosPost("/basis-server/basis/invoice/once", data, {
    timeout: 300000
  });
};
// 重新开票
export const invoiceOnceRe = data => {
  return axiosPost("/basis-server/basis/invoice/onceRe", data, {
    timeout: 300000
  });
};
// 实时查询全国公司信息
export const queryCustomHeader = data => {
  return axiosGet("/basis-server/basis/header/queryCustomHeader", data);
};
// 获取销方信息
export const invoiceSellerInfo = data => {
  return axiosPost("/basis-server/basis/invoice/get/seller/info", data);
};
// 获取开票结果
export const invoiceResult = data => {
  return axiosPost("/basis-server/basis/invoice/get/inv/result", data);
};
// 单张开票--保存草稿（暂存数据）
export const temporaryStorage = data => {
  return axiosPost("/basis-server/basis/invoice/temporary/storage", data);
};
// 单张开票--获取草稿数据（暂存数据）
export const temporaryStorageData = data => {
  return axiosPost(
    "/basis-server/basis/invoice/get/temporary/storage/data",
    data
  );
};
// 获取人脸状态
export const faceStatus = data => {
  return axiosPost("/basis-server/basis/invoice/get/face/status", data, {
    timeout: 300000
  });
};
// 税务登录
export const swLogin = data => {
  return axiosPost("/basis-server/basis/invoice/sw/login", data, {
    timeout: 300000
  });
};
// 税务登录发送验证码
export const swLoginSms = data => {
  return axiosPost("/basis-server/basis/invoice/sw/login/sms", data);
};
// 获取人脸二维码
export const qrCodeInfo = data => {
  return axiosPost("/basis-server/basis/invoice/get/qrCode/info", data, {
    timeout: 300000
  });
};
// 查询税局登录状态
export const getRPAStatus = data => {
  return axiosGet("/basis-server/basis/invoice/getRPAStatus", data);
};

// 审核开票
export const examineApi = {
  // 分页查询所有数据--店铺
  examineInvoiceList: data => {
    return axiosPost("/basis-server/basis/examine/getPage/by/shop", data);
  },
  // 通过主键查询单条数据
  examineGetInfo: data => {
    return axiosPost("/basis-server/basis/examine/getInfo", data);
  },
  // 修改该条数据信息
  examineUpdate: data => {
    return axiosPost("/basis-server/basis/examine/update", data, {
      timeout: 300000
    });
  }
};

// 扫码开票
export const scanCodeApi = {
  // 生成二维码
  generateQRCode: data => {
    return axiosPost("/basis-server/basis/invoice/qr/code/generate", data);
  },
  // 获取二维码管理分页列表
  qrCodeList: data => {
    return axiosPost("/basis-server/basis/invoice/get/qr/code/page", data);
  }
};

// 抬头管理
export const customApi = {
  // 抬头分类菜单(下同)列表
  typeListApi: data => {
    return axiosPost("/basis-server/basis/custom/classification/getList", data);
  },
  // 添加抬头分类
  addTypeApi: data => {
    return axiosPost("/basis-server/basis/custom/classification/add", data);
  },
  // 修改抬头分类
  editTypeApi: data => {
    return axiosPost("/basis-server/basis/custom/classification/update", data);
  },
  // 删除抬头分类
  delTypeApi: data => {
    return axiosPost("/basis-server/basis/custom/classification/delete", data);
  },
  // 抬头信息表格数据
  tablelistApi: data => {
    return axiosPost("/basis-server/basis/custom/info/getPage", data);
  },
  // 查询抬头信息单条数据
  getInfoApi: data => {
    return axiosPost("/basis-server/basis/custom/info/getInfo", data);
  },
  // 添加抬头信息单条数据
  addInfoApi: data => {
    return axiosPost("/basis-server/basis/custom/info/add", data);
  },
  // 修改抬头信息单条数据
  editInfoApi: data => {
    return axiosPost("/basis-server/basis/custom/info/update", data);
  },
  // 删除抬头信息单条数据
  delInfoApi: data => {
    return axiosPost("/basis-server/basis/custom/info/delete", data);
  }
};

// 商品管理
export const goodsApi = {
  // 商品分类菜单列表
  typeListApi: data => {
    return axiosPost(
      "/basis-server/basis/productClassificationManage/getList",
      data
    );
  },
  // 添加商品分类
  addTypeApi: data => {
    return axiosPost(
      "/basis-server/basis/productClassificationManage/add",
      data
    );
  },
  // 获取税收分类编号
  codeOptionsApi: data => {
    return axiosPost("/basis-server/basis/manage/code/getList", data);
  },
  // 修改商品分类
  editTypeApi: data => {
    return axiosPost(
      "/basis-server/basis/productClassificationManage/update",
      data
    );
  },
  // 删除商品分类
  delTypeApi: data => {
    return axiosPost(
      "/basis-server/basis/productClassificationManage/delete",
      data
    );
  },
  // 商品信息表格列表
  tablelistApi: data => {
    return axiosPost("/basis-server/basis/product/info/manage/getPage", data);
  },
  // 查询商品信息单条数据
  getInfoApi: data => {
    return axiosPost("/basis-server/basis/product/info/manage/getInfo", data);
  },
  // 添加商品信息单条数据
  addInfoApi: data => {
    return axiosPost("/basis-server/basis/product/info/manage/add", data);
  },
  // 修改商品信息单条数据
  editInfoApi: data => {
    return axiosPost("/basis-server/basis/product/info/manage/update", data);
  },
  // 删除商品信息单条数据
  delInfoApi: data => {
    return axiosPost("/basis-server/basis/product/info/manage/delete", data);
  }
};
