export default {
  name: "wuxSignArea",
  label: "签署区",
  icon: "el-icon-edit",
  type: 2,
  panelValue: "",
  schemas: [
    {
      schemaLabel: "字段标识",
      schemaKey: "key",
      schemaType: "text",
      schemaValidator: (rule, value, callback) => {
        callback(
          /^$|^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)
            ? void 0
            : new Error("不符合命名规范!")
        );
      }
    },
    {
      schemaLabel: "是否必填",
      schemaKey: "schemaRequired",
      schemaType: "switch",
      schemaRequired: true,
      props: {
        activeColor: "#13ce66",
        inactiveColor: "#ff4949"
      }
    },
    {
      schemaLabel: "控件名称",
      schemaKey: "schemaLabel",
      schemaType: "text"
    },
    {
      schemaLabel: "签署方",
      schemaKey: "signBy",
      schemaType: "select",
      schemaRequired: true,
      props: {
        options: []
      }
    }
  ],
  panelConfigurations: {
    key: "",
    schemaRequired: false,
    schemaLabel: "签署区",
    signBy: ""
  },
  panelBeforeCreate(p) {
    return {
      ...p,
      schemas: p.schemas.map(schema => {
        if (schema.schemaKey === "signBy") {
          return {
            ...schema,
            props: { options: this._props.signByOptions }
          };
        }
        return schema;
      })
    };
  }
};
