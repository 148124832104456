<script>
import { createToken, isScan } from "@/api/login";
import qrcode from "qrcode";

export default {
  data() {
    return {
      timer: null,
      status: "pc未扫码",
      token: ""
    };
  },
  created() {
    this.makeCode(false);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    makeCode(needRefresh) {
      // 如果点击刷新/切换 账号 需结束此轮轮询
      if (needRefresh) {
        clearInterval(this.timer);
      }
      // 是新 生成的 二为嘛
      this.status = "pc未扫码";
      // 随机数
      let csrfToken = Math.random() * 10000000;
      createToken({ csrfToken }).then(({ data }) => {
        // 测试地址
        // const text = `https://open.weixin.qq.com/sns/getexpappinfo?appid=wxbc300171036a2b64&path=pages%2Ftest%2Findex.html?token=${data}#wechat-redirect`;
        // 线上地址
        const text = `https://app.shupiaopiao.cn?jump=home&token=${data}`;
        const elText = this.$refs.qrcodeRef;
        qrcode.toCanvas(elText, text, err => {
          if (err) return;
          this.isScanFn(csrfToken, data);
        });
      });
    },
    // 轮询后台是是否扫码
    isScanFn(csrfToken, wechatToken) {
      this.timer = setInterval(() => {
        isScan({ csrfToken, token: wechatToken }).then(({ data }) => {
          // 获取到状态 结束轮询
          this.status = data;
          console.log("status", data);
          switch (this.status) {
            case "用户取消登录":
              clearInterval(this.timer);
              break;
            case "token已过期":
              clearInterval(this.timer);
              break;
            case "用户已扫码":
              break;
            case "成功":
              clearInterval(this.timer);
              this.$emit("login", { wechatToken });
              break;
            default:
              break;
          }
        });
      }, 4000);
    }
  }
};
</script>

<template>
  <div class="qrcodes">
    <canvas class="qrcode" ref="qrcodeRef" />
    <div v-show="status === 'pc未扫码'" class="tip">
      请使用微信扫码登录
      <span @click="makeCode(true)">刷新</span>
    </div>
    <div
      v-show="status === 'token已过期'"
      class="covering"
      @click="makeCode(true)"
    >
      <i class="el-icon-refresh-left refresh-icon"></i>
      <div class="mt16">二维码已失效</div>
    </div>
    <div v-show="status === '用户已扫码'" class="covering">
      <div class="success1">扫码成功</div>
      <div>请在手机端完成操作</div>
      <div class="codeBtn tip">
        <!-- <el-button round @click="makeCode(true)" size="mini" type="primary">切换账号</el-button> -->
        是否切换账号
        <span @click="makeCode(true)">切换</span>
      </div>
    </div>
    <div
      v-show="status === '用户取消登录'"
      class="covering"
      @click="makeCode(true)"
    >
      <i class="el-icon-refresh-left refresh-icon"></i>
      <div class="mt16">你已取消登录</div>
    </div>
  </div>
</template>

<style scoped lang="less">
.qrcodes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode {
  background: #fff;
  width: 170px !important;
  height: 170px !important;
}
.tip {
  margin-top: 10px;
  font-size: 0.5em;
  color: #b1bac8;

  span {
    cursor: pointer;
    color: #4389ff;
  }
}

.success1 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.covering {
  position: absolute;
  width: 200px !important;
  height: 200px !important;
  top: calc(50% - 156px);
  left: calc(50% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  color: #ffffff;
  cursor: pointer;
  .refresh-icon {
    // color: #4389ff;
    font-size: 50px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .codeBtn {
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    display: flex;
    // align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      margin-left: 5px;
    }
  }
}
</style>
