<script>
export default {
  data() {
    return {
      loginForm: {
        account: "",
        password: "",
        code: ""
      },
      rules: {
        account: [{ required: true, message: "请输入账户", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  methods: {
    toLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { account, password } = this.loginForm;
          this.$emit("login", { phone: account, password });
        }
      });
    }
  }
};
</script>

<template>
  <div class="warp">
    <div class="login-form">
      <el-form ref="form" :model="loginForm" :rules="rules">
        <el-form-item prop="account">
          <el-input
            v-model="loginForm.account"
            clearable
            placeholder="请输入账号"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            clearable
            placeholder="请输入密码"
            show-password
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="login-btn">
      <el-button type="primary" @click="toLogin()"> 登录 </el-button>
    </div>
  </div>
</template>

<style scoped lang="less">
.warp {
  width: 100%;
  .login-form {
    margin-top: 5px;
    width: 100%;

    .el-form {
      // width: 70%;
      margin: 0 auto;

      .login-form-code {
        display: flex;

        .login-form-code-img {
          width: 100px;
          margin-left: 10px;
          border: 1px solid #f0f0f0;
        }
      }

      .el-form-item {
        margin-bottom: 40px;
      }
    }
  }

  .login-btn {
    width: 100%;
    color: #606266;

    .el-button {
      width: 100%;
    }
  }
}
</style>
