// 自动计算高度
const getHeight=(ref) =>{
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const windowHeight = window.innerHeight;
    // 进行其他调整或计算...
    const distanceFromTop = ref.getBoundingClientRect().top + scrollTop; // 距离屏幕顶部的距离
    const height = windowHeight - distanceFromTop; // 屏幕总高度减去距离

    return height;
};
/**
 * 防抖函数非立即执行版
 * @author waldon
 * @date 2024-01-17
 * @returns {Function} - 防抖函数
 */
const debounce = (function () {
    let timer = 0
    return function (fn, delay = 300) {
      if (timer) {
        clearTimeout(timer)
      }
      // 非立即执行
      timer = setTimeout(() => {
        fn()
      }, delay)
    }
  })()
  
export {getHeight, debounce};

