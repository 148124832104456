import { axiosPost, axiosGet } from "@/utils/httpConfig";
import qs from "qs";

// export const qrCodeScan = data => {
//     // ?csrfToken=csrfToken
//     return axios.axiosPost(`${authServerUrl}qrCode/scan?token=${data}`);
// };

//  二维码token
export const createToken = data => {
  return axiosGet("/auth-server/auth/create/token", data);
};
// 轮询状态
export const isScan = data => {
  return axiosGet("/auth-server/auth/check/login/status", data);
};
// 账号登录token
export const oauthToken = data => {
  return axiosPost(`/auth-server/auth/oauth/token?${qs.stringify(data)}`, {});
};
// 登录
export const login = data => {
  return axiosPost("/login", data);
};
// 获取菜单列表
export function menuCurrentList() {
  return axiosGet("/system/menu/current/list");
}
// 获取用户信息
export function userCurrentInfo() {
  return axiosPost("/user/current/info");
}
// POST获取验证码
export function captcha() {
  return axiosPost("/captcha");
}
// DELETE统一退出
// export function tokenAll() {
//     return delete'/token/all';
// }

export function menuAdd(data) {
  return axiosPost("/auth-server/auth/pc/menu/add", data);
}

export function getMenu(data) {
  return axiosPost("/auth-server/auth/getPerson/topInfo", data);
}

export function menuGetPage(data) {
  return axiosPost("/auth-server/auth/pc/menu/getPage", data);
}
