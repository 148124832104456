<template>
  <div class="main">
    <div
      :class="[
        'resizeable-div',
        { indicator: isIndicator },
        { 'resizeable-require': panelConfigurations.schemaRequired }
      ]"
      :style="{ width: panelConfigurations.width + 'px' }"
    >
      <span>{{ panelConfigurations.schemaLabel }}</span>
      <span
        v-show="!isIndicator"
        class="resizeable-handle"
        @mousedown.prevent="startResize"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isIndicator: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isResizing: false,
      startX: 0
    };
  },
  computed: {
    panelConfigurations: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    startResize(event) {
      this.isResizing = true;
      this.startX = event.clientX;
    },
    resize(event) {
      if (!this.isResizing) {
        return;
      }

      const diff = event.clientX - this.startX;
      // eslint-disable-next-line vue/no-mutating-props
      this.$set(
        this.panelConfigurations,
        "width",
        this.panelConfigurations.width + diff < 10
          ? 10
          : this.panelConfigurations.width + diff
      );
      this.startX = event.clientX;
    },
    stopResize() {
      this.isResizing = false;
    }
  },
  mounted() {
    window.addEventListener("mousemove", this.resize);
    window.addEventListener("mouseup", this.stopResize);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.resize);
    window.removeEventListener("mouseup", this.stopResize);
  }
};
</script>

<style lang="less" scoped>
.resizeable-div {
  position: relative;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  color: #000;
  background: rgb(231, 244, 255);
  opacity: 0.9;

  .resizeable-handle {
    position: absolute;
    top: calc(50% - 5px);
    right: -5px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    cursor: ew-resize;
    background-color: grey;
  }
}
.resizeable-require:before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.indicator {
  background: rgba(244, 250, 255, 0.9) !important;
  border: 1px dashed rgb(16, 99, 241) !important;
  border-radius: 2px !important;
  color: rgb(189, 189, 190) !important;
}
.el-input {
  width: 100%;
}
</style>
