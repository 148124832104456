import { axiosGet } from '@/utils/httpConfig';

//  获取个人账户详细信息
export const getAccountInfoApi = data => {
    return axiosGet('/auth-server/auth/getAccountInfo', data);
};
//  更新个人账户信息
export const updateAccountInfoApi = data => {
    return axiosGet('/auth-server/auth/updateAccountInfo', data);
};


