const dynamicRoutes = [
  {
    component: () => import("@/views/workbench/index.vue"),
    name: "workbench" // 工作台
  },
  {
    component: () => import("@/views/company/index.vue"),
    name: "company" // 公司
  },
  {
    component: () => import("@/views/invoicing/invoice_single/index.vue"),
    name: "invoice-single" // 单张开票
  },
  {
    component: () => import("@/views/invoicing/invoice_review/index.vue"),
    name: "invoice-review" // 审核开票
  },
  {
    component: () => import("@/views/invoicing/invoice_scan_code/index.vue"),
    name: "invoice-scan-code" // 扫码开票
  },
  {
    component: () => import("@/views/invoicing/invoice_message/index.vue"),
    name: "invoice-message" // 开票信息管理（商品管理/抬头管理）
  },
  {
    component: () => import("@/views/custom/custom_signing/index.vue"),
    name: "custom-signing" // 签约管理
  },
  {
    component: () => import("@/views/custom/custom/index.vue"),
    name: "custom-custom" // 店铺管理
  },
  {
    component: () => import("@/views/custom/custom_store/index.vue"),
    name: "custom-store" // 门店管理
  },
  {
    component: () => import("@/views/custom/custom_code/index.vue"),
    name: "custom-code" // 激活码
  },
  {
    component: () => import("@/views/custom/custom_apply/index.vue"),
    name: "custom-apply" // 申请信息
  },
  {
    component: () => import("@/views/data/invoice_records/index.vue"),
    name: "invoice-records" // 发票记录
  },
  {
    component: () => import("@/views/data/order_management/index.vue"),
    name: "order-management" // 订单管理
  },
  {
    component: () => import("@/views/marketing/commodity_management/index.vue"),
    name: "commodity-management" // 商品管理
  },
  {
    component: () =>
      import("@/views/marketing/advertising_management/index.vue"),
    name: "advertising-management" // 广告管理
  },
  {
    component: () => import("@/views/personal_settings/index.vue"),
    name: "personal-settings-hasrouter" // 个人设置-显示路由菜单
  },
  {
    component: () => import("@/views/personal_settings/index.vue"),
    name: "personal-settings-norouter" // 个人设置-不显示路由菜单
  },
  {
    component: () => import("@/views/contract/index.vue"),
    name: "design" // 合同设计
  },
  {
    component: () =>
      import("@/views/system_preferences/staff_management/index.vue"),
    name: "staffManagement" // 员工管理
  },
  {
    component: () =>
      import("@/views/system_preferences/role_management/index.vue"),
    name: "roleManagement" // 角色管理
  },
  {
    component: () =>
      import("@/views/system_preferences/menu_management/index.vue"),
    name: "menuManagement" // 菜单管理
  }
];

export default dynamicRoutes;
