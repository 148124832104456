<template>
  <el-tabs v-model="activeTabName" @tab-click="handleClick" :stretch="true" class="leftWrap">
    <el-tab-pane v-for="elTab in elTabList" :key="elTab.id" :label="elTab.label" :name="elTab.name"
                 class="leftWrap-pane">
      <div v-for="wuxComponent in wuxComponentsList" :key="wuxComponent.id" v-show="!wuxComponent.disabled"
           draggable="true" @dragstart="dragStart($event, wuxComponent)"
           @dragend="$emit('changeIndicatorStatus', false)"
           class="leftWrap-pane_wrap">
        <i :class="[wuxComponent.icon, 'leftWrap-pane_wrap_logo']" />
        <div class="leftWrap-pane_wrap_title">
          {{ wuxComponent.label }}
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
const indicatorImage = new Image();
indicatorImage.src = require("@/assets/images/wux_contract/transparent.png");
export default {
  name: "wuxComponents",
  props: {
    wuxComponentsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // tab列表
      elTabList: [
        { id: 0, name: "first", label: "常用组件" },
        { id: 1, name: "second", label: "全部组件" }
      ],
      // 当前激活的tab
      activeTabName: "second"
    };
  },
  methods: {
    /**
     * el tab 点击
     * @param {Object} tab
     */
    handleClick(tab) {
      this.activeName = tab.name;
    },
    /**
     * 拖动 开始
     * @param {Object} event 原生 拖动 事件 对象
     * @param {Object} wuxComponent 被 拖动 对象
     */
    dragStart(event, wuxComponent) {
      // 隐藏 指示器
      event.dataTransfer.setDragImage(indicatorImage, 0, 0);
      event.dataTransfer.setData("text/plain", JSON.stringify({name: wuxComponent.name}));
      event.dataTransfer.effectAllowed = "move";
      this.$emit("changeIndicatorStatus", true, wuxComponent);
    }
  }
};
</script>

<style scoped lang="less">
@import "@/assets/css/base";

.leftWrap {
  width: 240px;
  padding: 10px;
  box-sizing: border-box;
  background-color: @color-white;

  .leftWrap-pane {
    .leftWrap-pane_wrap {
      position: relative;
      text-align: center;
      display: inline-block;
      width: 70px;
      height: 80px;
      font-size: 12px;
      margin-bottom: 8px;
      vertical-align: top;
      cursor: pointer;
      z-index: 9999;

      .leftWrap-pane_wrap_logo {
        display: inline-block;
        margin-top: 8px;
        background-position: 0 0;
        font-size: 30px;
      }

      .leftWrap-pane_wrap_title {
        color: #323233;
        line-height: 18px;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
</style>
