<template>
    <div class="message_notification">消息通知-----待开发</div>
</template>
<script>
export default {
    data() {
        return {
            storeList: [
                {
                    name: '创建者'
                }
            ]
        };
    },
    created() {
        this.getDataFn();
    },
    methods: {
        getDataFn() {
            // console.log('获取初始数据');
        },

    }
};
</script>
<style scoped lang="less">
@import "@/assets/css/main.less";
.message_notification{
    width: calc(100% - 28px);
    margin: 0 14px;  
}
 
</style>
