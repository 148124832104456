<template>
    <el-tag v-if="value" :type="changeType(getLabel(value, options))" :size="size">{{ getLabel(value, options) }}</el-tag>
</template>
<script>
export default {
    name: 'tagComp',
    props: {
        size: {
            type: String,
            default: 'small'
        },
        value: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            tagType: 'primary'
        };
    },
    // watch: {
    //     options: {
    //         handler(newVal) {
    //         },
    //         immediate: true,
    //         deep: true
    //     }
    // },
    methods: {
        changeType(value) {
            if (value === '停放' || value === '禁用' || value === '离线') {
                return 'danger';
            }
            return 'primary';
        },
        // 根据下拉值 返回回显
        getLabel(value, options){
            let labelName = '';

            options.forEach(el => {
                if ( el.value === value) {
                    labelName = el.label;
                }
            });

            return labelName;
        }
    }
};
</script>
<style scoped lang="less">
</style>
