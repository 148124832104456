<template>
  <div ref="table">
    <el-table
      ref="elTable"
      :data="tableData"
      :height="defaultTableHeight || tableHeight"
      v-loading="loading"
      element-loading-text="加载中"
      @sort-change="handleSortChange"
      @hook:updated="calculateHeight"
      :row-key="rowKey"
      :lazy="lazy"
      :load="load"
      :tree-props="treeProps"
      :row-class-name="tableRowClassName"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isMultipleChoice"
        type="selection"
        width="55"
        :fixed="selectionFixed"
        :selectable="selectable"
      />
      <!-- 备注信息 -->
      <el-table-column
        label="序号"
        type="index"
        width="80"
        v-if="isShowIndex"
        :fixed="indexFixed"
        class="msfsdfaaa"
      >
        <template v-slot="scope">
          {{ scope.$index + 1 }}
          <div
            class="remark-info-style"
            v-if="isShowRemark && scope.row.remark"
          >
            备注：<span>{{ scope.row.remark }}</span>
            <span v-if="isShowReason && scope.row.reason"
              >，原因：{{ scope.row.reason }}</span
            >
          </div>
          <div
            class="remark-info-style"
            v-if="isShowRemark && scope.row.errMessage"
          >
            失败原因：<span>{{ scope.row.errMessage }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
        :sortable="column.sortable"
        :formatter="column.formatter"
        :show-overflow-tooltip="column.showOverflowTooltip"
        :width="column.width"
        :fixed="column.fixed"
      >
        <template v-slot="scope">
          <slot
            v-if="column.slot"
            :name="column.slot"
            :rowData="scope.row"
            :column="column"
          />
          <selectComp
            v-if="column.type === 'select'"
            v-model="scope.row"
            :column="column"
          />
          <!-- 状态tag标签 -->
          <tagComp
            v-if="column.type === 'tag'"
            :value="scope.row[column.prop]"
            :options="column.options"
          />
          <!-- 多张图片展示及预览 -->
          <template v-if="column.type === 'image' && scope.row[column.prop]">
            <el-image
              v-for="(item, index) in Array.isArray(scope.row[column.prop])
                ? scope.row[column.prop]
                : Object.prototype.toString.call(
                    JSON.parse(scope.row[column.prop])
                  ) === '[object Array]'
                ? JSON.parse(scope.row[column.prop])
                : [scope.row[column.prop]]"
              :key="index"
              style="width: 100px; height: 100px; margin-right: 10px"
              :src="item"
              :preview-src-list="
                Array.isArray(scope.row[column.prop])
                  ? scope.row[column.prop]
                  : Object.prototype.toString.call(
                      JSON.parse(scope.row[column.prop])
                    ) === '[object Array]'
                  ? JSON.parse(scope.row[column.prop])
                  : [scope.row[column.prop]]
              "
            />
          </template>
          <!-- 没有 slot 且 没有column type 或者 为 text 时 都默认为 文本-->
          <!-- 可编辑文本 column.editable整列可编辑  scope.row.editable 当前行可编辑  -->
          <template
            v-if="(column.type === 'text' || !column.type) && !column.slot"
          >
            <el-input
              v-if="scope.row.editable || column.editable"
              v-model="scope.row[column.prop]"
              placeholder="请输入"
            />
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isOperation"
        label="操作"
        :width="btnWidth"
        :fixed="operationFixed"
      >
        <template v-slot="scope">
          <slot
            v-if="$scopedSlots?.tableOperations"
            name="tableOperations"
            :rowData="scope.row"
          />
          <template v-else>
            <el-button
              v-for="operation in tableOperations"
              :key="operation.name"
              type="text"
              :style="`color: ${getButtonColor(operation.type)}`"
              @click="handleClick(operation, scope.row)"
            >
              {{ operation.name }}
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        v-if="isPagination"
        class="basicTable-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="btns">
        <slot name="btns"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import tagComp from "./components/tagCom.vue";
import selectComp from "./components/select.vue";

export default {
  components: {
    tagComp,
    selectComp
  },
  props: {
    // 是否 显示 序号
    isShowIndex: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      required: true
    },
    tableOperations: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      required: true
    },
    // 红色备注信息是否显示
    isShowRemark: {
      type: Boolean,
      default: false
    },
    // 红色备注信息中是否显示--原因内容
    isShowReason: {
      type: Boolean,
      default: false
    },
    // 是否显示操作列
    isOperation: {
      type: Boolean,
      default: true
    },
    // 操作按钮宽度
    btnWidth: {
      type: String,
      default: ""
    },
    // 外部指定 距离底部距离
    heightFromBottom: {
      type: Number,
      default: 90
    },
    // 外部指定高度
    defaultTableHeight: {
      type: Number,
      default: 0
    },
    // 是否多选
    isMultipleChoice: {
      type: Boolean,
      default: false
    },
    // 是否分页
    isPagination: {
      type: Boolean,
      default: true
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 15, 20]
    },
    current: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 400
    },
    // 树类型的数据的显示
    rowKey: {
      type: String,
      default: "id"
    },
    treeProps: {
      type: Object,
      default: () => ({ children: "child", hasChildren: "hasChildren" })
    },
    lazy: {
      type: Boolean,
      default: false
    },
    load: {
      type: Function
    },
    // 序号列 定位
    indexFixed: {
      type: [String, Boolean]
    },
    // 选择列 定位
    selectionFixed: {
      type: [String, Boolean]
    },
    // 当前行是否可选
    selectable: {
      type: Function
    },
    // 操作列 定位
    operationFixed: {
      type: [String, Boolean]
    }
  },
  data() {
    return {
      loading: false,
      tableHeight: "auto",
      currentPage: 1
    };
  },
  computed: {
    getButtonColor() {
      return function (type) {
        let color = "";

        switch (type) {
          case "del":
          case "red":
            color = "red";
            break;
          // 其他颜色在这里判断 并赋值
          default:
            break;
        }
        return color;
      };
    }
  },
  watch: {
    // tableData(newData) {
    //     // console.log(newData);
    //     // 监听外部传入的表格数据变化，实现实时更新
    //     // 可以在这里进行一些处理逻辑
    // }
  },
  methods: {
    // 自动计算高度
    calculateHeight() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const componentTable = this.$refs.table;
      // 进行其他调整或计算...
      const distanceFromTop =
        componentTable.getBoundingClientRect().top + scrollTop; // 距离屏幕顶部的距离
      const heightFromTop = windowHeight - distanceFromTop; // 屏幕总高度减去距离

      this.tableHeight = heightFromTop - this.heightFromBottom;
    },
    handleClick(button, row) {
      if (button.type === "del") {
        return this.$confirm("此操作将永久删除此条数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$emit("click", button.type, row);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
      this.$emit("click", button.type, row);
    },
    handleSortChange(sort) {
      this.$emit("sort", sort);
    },
    handleSizeChange(val) {
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("currentChange", val);
    },
    /**
     * 给table添加class类名
     * 在某行需要备注信息时，需要添加不同的样式
     */
    tableRowClassName(data) {
      if ((data.row.remark && data.row.remark !== "") || data.row.errMessage) {
        return "remark-row-style";
      }
    },
    /**
     * 触发 父组件 更新 多选 数据
     * @param {*} val
     */
    handleSelectionChange(val) {
      this.$emit("selectionChange", val);
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .remark-row-style td.el-table__cell {
  padding: 12px 0 36px 0 !important;
}

.remark-info-style {
  width: 50vw;
  position: absolute;
  z-index: 999;
  left: 10px;
  bottom: 6px;
  color: #d54234;
  background-color: #ffe5e3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basicTable-pagination {
  margin-top: 25px;
}

.pagination {
  position: relative;
  .btns {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 28px;
  }
}
</style>
