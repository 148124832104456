export default {
  name: "wuxResizeableNumber",
  label: "数字",
  icon: "el-icon-mobile",
  type: 1,
  panelValue: "",
  schemas: [
    {
      schemaLabel: "字段标识",
      schemaKey: "key",
      schemaType: "text",
      schemaValidator: (rule, value, callback) => {
        callback(
          /^$|^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)
            ? void 0
            : new Error("不符合命名规范!")
        );
      }
    },
    {
      schemaLabel: "是否必填",
      schemaKey: "schemaRequired",
      schemaType: "switch",
      schemaRequired: true,
      props: {
        activeColor: "#13ce66",
        inactiveColor: "#ff4949"
      }
    },
    {
      schemaLabel: "宽度",
      schemaKey: "width",
      schemaType: "number",
      props: {
        min: 10
      }
    },
    {
      schemaLabel: "控件名称",
      schemaKey: "schemaLabel",
      schemaType: "text"
    },
    {
      schemaLabel: "数字格式",
      schemaKey: "schemaRulesNumberTypes",
      schemaType: "select",
      props: {
        options: [
          { label: "整数", value: 1 },
          { label: "通用数字", value: 2 },
          { label: "保留一位小数", value: 3 },
          { label: "保留两位小数", value: 4 }
        ]
      }
    },
    {
      schemaLabel: "schemaType",
      schemaKey: "schemaType",
      schemaType: "text",
      hidden: true
    }
  ],
  panelConfigurations: {
    key: "",
    schemaRequired: false,
    width: 145,
    schemaLabel: "数字",
    schemaType: "number",
    schemaRulesNumberTypes: 1,
    props: {
      controlsPosition: "right"
    }
  },
  panelDestroyed: p => {
    const { panelConfigurations } = p;
    const { schemaRulesNumberTypes } = panelConfigurations;

    // 定义一个映射表，用于根据schemaRulesNumberTypes的值映射到对应的配置
    const typeToConfigMap = {
      1: { step: 1, precision: 0, stepStrictly: true },
      3: { step: 0.1, precision: 1, stepStrictly: true },
      4: { step: 0.01, precision: 2, stepStrictly: true }
    };

    // 如果当前的schemaRulesNumberTypes在映射表中，则更新p对象
    if (typeToConfigMap.hasOwnProperty(schemaRulesNumberTypes)) {
      return {
        ...p,
        panelConfigurations: {
          ...panelConfigurations,
          props: {
            ...panelConfigurations.props,
            // 直接从映射表中获取对应的配置更新到props中
            ...typeToConfigMap[schemaRulesNumberTypes]
          }
        }
      };
    }

    // 如果schemaRulesNumberTypes的值不在映射表中（包括2和默认情况），直接返回p
    return p;
  }
};
