import { axiosPost } from "@/utils/httpConfig";

// pc菜单管理器
export function menuAdd(data) {
  return axiosPost("/auth-server/auth/pc/menu/add", data, { loading: true });
}

export function getMenu(data) {
  return axiosPost("/auth-server/auth/getPerson/topInfo", data, {
    loading: true
  });
}

export function menuGetPage(data) {
  return axiosPost("/auth-server/auth/pc/menu/getPage", data, {
    loading: true
  });
}

export function deleteMenu(data) {
  return axiosPost("/auth-server/auth/pc/menu/delete/menu", data, {
    loading: true
  });
}

export function menuGetMenuInfo(data) {
  return axiosPost("/auth-server/auth/pc/menu/getMenu/info", data, {
    loading: true
  });
}

export function menuUpdate(data) {
  return axiosPost("/auth-server/auth/pc/menu/update", data, { loading: true });
}

// 角色控制层
export function roleGetPage(data) {
  return axiosPost("/auth-server/auth/role/getPage", data, { loading: true });
}

export function roleUpdateById(data) {
  return axiosPost("/auth-server/auth/role/updateById", data, {
    loading: true
  });
}

export function roleAdd(data) {
  return axiosPost("/auth-server/auth/role/add", data, { loading: true });
}

export function roleGetById(data) {
  return axiosPost("/auth-server/auth/role/getById", data, { loading: true });
}

export function roleDeleteById(data) {
  return axiosPost("/auth-server/auth/role/deleteById", data, {
    loading: true
  });
}

// PersonController
export function getRoleInfoByShopId(data) {
  return axiosPost("/auth-server/auth/getRole/info/by/shopId", data, {
    loading: true
  });
}

export function getPersonPage(data) {
  return axiosPost("/auth-server/auth/getPerson/page", data, { loading: true });
}

export function getPersonInfo(data) {
  return axiosPost("/auth-server/auth/getPerson/info", data, { loading: true });
}

export function addUser(data) {
  return axiosPost("/auth-server/auth/add/user", data, { loading: true });
}

export function updateUser(data) {
  return axiosPost("/auth-server/auth/update/user", data, { loading: true });
}

export function deleteUser(data) {
  return axiosPost("/auth-server/auth/delete/user", data, { loading: true });
}
