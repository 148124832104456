<template>
    <div class="img-com">
        <draggable class="image-list" style="display:flex" v-model="newFileList">
            <div v-for="(image, index) in newFileList" :key="index">
                <el-image :src="image" :preview-src-list="newFileList" />
                <i class="el-icon-delete" @click="delImg(index)"></i>
            </div>
            <el-upload class="avatar-uploader" :action="actionUrl" :headers="headers"
                :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess">
                <div class="images">
                    <i slot="trigger" class="el-icon-plus avatar-uploader-icon"></i>
                </div>
            </el-upload>
        </draggable>
    </div>
</template>
<script>
import store from '@/store/index.js';

export default {
    name: 'imageUploadComp',
    data() {
        return {
            newFileList: [],
            actionUrl: '/minio-server/minio/upload/by/type',
            headers: {
                Authorization: store.state.loginData.userToken
            }
        };
    },
    props: {
        //value 是 FileList
        value: {
            type: Array,
            default: () => []
        },
        // 目前 type有以下
        // 商品图片传0 商品分组图片传2
        // 个人头像传1
        // 软件版本传3 硬件版本传4
        type: {
            type: Number,
            default: 1
        },
        limit: {
            type: Number,
            default: 5
        },
        imgList: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        // 抛出值
        newFileList() {
            this.$emit('input', this.newFileList);

        }
    },
    created() {
        if (this.value && this.value.length > 0) {this.newFileList = JSON.parse(this.value);}
        this.actionUrl = `${process.env.VUE_APP_API_URL}${this.actionUrl}?type=${this.type}`;
    },
    methods: {

        beforeAvatarUpload(file) {
            const isLimit = this.limit > this.newFileList.length;
            const isJPG = file.type.startsWith('image/');
            if (!isLimit) {
                this.$message.error(`最多只能上传${this.limit}张图片`);
            }
            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG 格式!');
            }
            return isLimit && isJPG;
        },
        handleAvatarSuccess(res) {
            this.newFileList.push(`${process.env.VUE_APP_FILE_URL}/${res.data}`);
        },
        delImg(index) {
            this.newFileList.splice(index, 1)
        }
    }
};

</script>
<style scoped lang='less'>
.avatar-uploader {
    display: flex;
    justify-content: start;
    width: 100%;
}

.images {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.img-com {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
}

.image-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;

    div {
        position: relative;
        text-align: center;

        /deep/ img {
            width: 70px;
            height: 70px;
            cursor: pointer;
        }

        &:hover .el-icon-delete {
            display: block;
        }

        .el-icon-delete {
            position: absolute;
            right: 10px;
            top: 10px;
            color: brown;
            display: none;
            cursor: pointer;
        }
    }

}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

/deep/ .el-upload-list {
    display: none;
}

/deep/ .el-upload--picture-card {
    border: none !important;
}

.avatar-uploader-icon {
    border: 1px solid #8c939d;
    font-size: 28px;
    color: #8c939d;
    width: 68px;
    height: 68px;
    line-height: 68px;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}
</style>

