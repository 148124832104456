import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import _ from "lodash";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/utils/btnPermission";
import wuxForm from "@/components/wux_form/index.jsx";
Vue.component("wuxForm", wuxForm);
import draggable from 'vuedraggable'
Vue.component('draggable', draggable)
Vue.config.productionTip = false;

Vue.prototype.$_ = _;

Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
