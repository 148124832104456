<template>
  <aside class="aside">
    <!--菜单-->
    <dynamicFlipMenu />
    <!--账号-->
    <avatar style="width: 100%" />
    <!--折叠-->
    <collapse />
  </aside>
</template>

<script>
import avatar from "./components/avatar.vue";
import dynamicFlipMenu from "./components/dynamic-flip-menu.vue";
import collapse from "./components/collapse.vue";

export default {
  components: {
    avatar,
    dynamicFlipMenu,
    collapse
  }
};
</script>

<style scoped lang="less">
.el-menu {
  border: none;
}

.aside {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fafbfc;
  position: relative;
}
</style>
