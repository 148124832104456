<template>
    <div class="home">
        <Nav v-if="!hasrouter" class="layout-nav" :isCompanyNav="true" />
        <customHeader routerName="个人设置" />
        <div :style="heightStyle" ref="contentRef">
            <settingsMenu @change="changeMenu" :showBank="!hasrouter"></settingsMenu>
            <div class="right-content" style="">
                <accountEttings v-if="menuIndex === 0"></accountEttings>
                <storeSettings v-if="menuIndex === 1"></storeSettings>
                <messageNotification v-if="menuIndex === 2"></messageNotification>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from '@/views/layout/nav/index.vue';
import customHeader from '@/components/custom_header/index.vue';
import settingsMenu from './components/settings_menu.vue';
import accountEttings from './components/account_ettings.vue';
import storeSettings from './components/store_settings.vue';
import messageNotification from './components/message_notification.vue';
import { getHeight } from '@/utils/utils';

export default {
    components: {
        Nav,
        customHeader,
        settingsMenu,
        accountEttings,
        storeSettings,
        messageNotification
    },
    data() {
        return {
            heightStyle: {
                display: 'flex',
                height: '0px'
            },
            menuIndex: 0,
            hasrouter:true

        };
    },
    computed: {
    },
    created() {
        this.hasrouter=this.$route.fullPath.includes('hasrouter');
        this.$nextTick(() => {
            this.calculateHeight();
        });
        window.addEventListener('resize', this.calculateHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateHeight);
    },
    methods: {
        changeMenu(i) {
            this.menuIndex = i;
        },
        // 自动计算高度
        calculateHeight() {
            this.heightStyle.height = getHeight(this.$refs.contentRef) + 'px';
        },
        // 根据路由名字，路由菜单是否显示，导航栏是否显示
    }

};

</script>
<style scoped lang="less">
@import "@/assets/css/main.less";

.right-content {
    flex: 1;
    margin: 20px;
    width: calc(100% - 40px);
}
</style>
