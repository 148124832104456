<template>
  <el-dialog title="认证" :visible.sync="faceStatus" width="25%" :before-close="handleClose">
    <div class="qrcode-style">
      <span style="padding-bottom: 10px; font-weight: bold;">开票人：{{ kpr }}</span>
      <span>请开票员</span>
      <span>使用税务APP扫码人脸认证</span>
      <div class="code-box"  v-loading="isMask" element-loading-text="二维码正在加载中">
        <canvas id="qrcode" ref="canvas" height="190px" width="190px" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>
  
<script>
import { swLogin, qrCodeInfo } from "@/api/invoicing";
import qrcode from "qrcode";

export default {
  data() {
    return {
      isShowLogin: false,
      shopId: null,
      kpr: '',
      isMask: true // 二维码遮罩层--默认开启
    }
  },
  computed: {
    faceStatus() {
      return this.$store.state.loginData.faceStatus;
    }
  },
  created() {
    this.shopId = this.$store.state.loginData.shopInfo.id;
    this.kpr = this.$store.state.loginData.shopInfo.kpr;
    this.isMask = true;
  },
  mounted() {
    if (this.faceStatus) {
      swLogin({ shopId: this.shopId })
        .then(res => {
          if (res.code === 1000) {
            this.isScanFn();
          }
        })
    }
  },
  methods: {
    handleClose() {
      this.$store.commit("loginData/faceStatus", false);
    },
    // 轮询--判断人脸二维码是否生成成功
    isScanFn() {
      qrCodeInfo({ shopId: this.shopId }).then(res => {
        if (res.code === 1000) {
          let codeInfo = res.data;

          if (codeInfo.length > 20) {
            this.isMask = false;
          }
          qrcode.toCanvas(this.$refs.canvas, codeInfo, err => {
            if (err) {
              return;
            }
          });
        }
      });
    }
  }
};
</script>
  
<style scoped lang="less">
.qrcode-style {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 16px;
  }

  .code-box {
    margin-top: 20px;

    #qrcode {
    }
  }
}
</style>
  