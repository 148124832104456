<template>
  <i
    :class="[
      isSidebarNavCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left',
      'aside-collapse_icon'
    ]"
    @click="changeIsSidebarNavCollapse()"
  />
</template>

<script>
import store from "@/store";

export default {
  computed: {
    isSidebarNavCollapse() {
      return store.state.loginData.isSidebarNavCollapse;
    }
  },
  methods: {
    /**
     * 更改 菜单 折叠 状态
     */
    changeIsSidebarNavCollapse() {
      this.$store.commit(
        "loginData/isSidebarNavCollapse",
        !this.isSidebarNavCollapse
      );
    }
  }
};
</script>
<style lang="less" scoped>
// 折叠
.aside-collapse_icon {
  position: absolute;
  box-shadow: 0 4px 8px rgba(32, 45, 64, 0.05), 0 1px 4px rgba(32, 45, 64, 0.08);
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  text-align: center;
  top: 68px;
  right: -12px;
  background: #fff;
}
</style>
