// 判断用户有没有按钮权限，获取路由可以针对不同页面处理
import Vue from 'vue';

const checkUserPermission = (permission) => {
    // 假设当前用户的权限列表为 ['create', 'edit']
    const userPermissions = ['create', 'edit', 'search', 'reset'];

    return userPermissions.includes(permission); // 检查当前权限是否在用户的权限列表中
};

Vue.directive('permission', {
    inserted(el, binding) {
        const permission = binding.value; // 获取指令绑定的权限值

        if (!checkUserPermission(permission)) { // 根据权限值检查用户权限
            el.style.display = 'none'; // 如果没有权限，则隐藏按钮
        }
    }
});
