<template>
    <div class="home">
        店铺管理-----待开发
        <div v-if="false" class="home-store">
            <img
                src="https://zhengxin-pub.cdn.bcebos.com/logopic/119fbb0c4fdc5f3671e6775b7aeb5d49_fullsize.jpg?x-bce-process=image/resize,m_lfit,w_200" />
            <div class="home-store-content">
                <div>重庆云屯智能科技有限公司</div>
                <div>身份：创建者</div>
            </div>
        </div>
        <div v-if="false" class="content1 home-search">
            <div class="content1-item">
                <customTitle title="账户余额"></customTitle>
                <div class="text mt20">可用店铺余额（元）：</div>
                <div class="num mt20">299.00</div>
                <div class="btn mt20">
                    <el-button type="primary">充值</el-button>
                    <el-button>提现</el-button>
                </div>
            </div>
        </div>
        <div v-if="false" class="content2 home-search">
            <customTitle title="记录"></customTitle>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-for="(v, i) in tabs" :key="i" :label="v.label" :name="v.name"></el-tab-pane>
            </el-tabs>
            <basicTable class="home-table" :columns="tableColumns" :tableData="tableData" @click="tableClick" rowKey="sort"
                :size="size" :current="current" :total="total" @sizeChange="handleSizeChange" :isOperation="false"
                @currentChange="handleCurrentChange" :isShowRemark="true">
            </basicTable>
        </div>
    </div>
</template>
<script>
import customTitle from '@/components/custom_title/index.vue';
import basicTable from '@/components/basic_table/basicTable.vue';
import { outTableColumns, investTableColumns } from './config/index'
export default {
    components: {
        customTitle,
        basicTable


    },
    data: () => ({
        activeName: '1',
        tabs: [
            { label: '提现记录', name: '1' },
            { label: '充值记录', name: '2' }
        ],
        tableColumns: outTableColumns,
        tableOperations: [
            { name: '回单', type: 'edit' }
        ],
        tableData: [
            {
                name2: '流水号', name3: '所属店铺', name4: '提现金额', name5: '提现申请时间', name6: '操作人', status: '1'
            },
            {
                name2: '流水号', name3: '所属店铺', name4: '提现金额', name5: '提现申请时间', name6: '操作人', status: '2',
                errMessage: '失败原因'
            },
            {
                name2: '流水号', name3: '所属店铺', name4: '提现金额', name5: '提现申请时间', name6: '操作人', status: '3'
            }
        ]
        // width:100%
    }),
    methods: {
        editBtn() {
            console.log('editBtn');
        },
        handleClick() {
            // this.tableColumns=this.activeName==='1'? outTableColumns :investTableColumns;
            if (this.activeName === '1') {
                this.tableColumns = outTableColumns;
                this.getOutHistory();
            } else {
                this.tableColumns = investTableColumns;
                this.getInvestistory();
            }
        },
        tableClick(type, row) {
            console.log('行数据:', row);
            // 找到当前项的索引
            // eslint-disable-next-line no-case-declarations
            // let index = this.tableData.findIndex(v => {
            //     return v.sort === row.sort;
            // });

            switch (type) {
                case 'edit':
                    break;
                case 'del':
                    console.log('del');
                    break;
                case 'create':
                    console.log('创建子分组');
                    break;
                default:
                    console.log('不存在的事件');
            }
        },
        getOutHistory() {
        },
        getInvestistory() {
        }

    }
};
</script>
<style lang="less" scoped>
.mt20 {
    margin-top: 20px;
    margin-left: 20px;
}

.home {
    width: calc(100% - 28px);
    margin: 0 14px;

    .home-store {
        display: flex;

        img {
            width: 85px;
            height: 85px;
        }

        .home-store-content {
            margin: 20px;

            div:nth-of-type(1) {
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: bold;
                color: #333333;
            }

            div:nth-of-type(2) {
                font-size: 14px;
                color: #999999;
            }
        }
    }

    .content1 {
        display: flex;
        justify-content: space-between;
        margin: 14px 0;
        font-size: 14px;
        color: #333333;

        .content1-item {
            width: calc(50% - 4px);
            text-align: left;

            .text {
                span {
                    cursor: pointer;
                    color: #1890FF;
                }
            }

            .num {
                font-size: 28px;
                font-weight: 700;
            }
        }
    }
}
</style>

