<template>
    <div class="basic-title">
        <div class="line"></div>
        <div class="text">{{title }}</div>
    </div>
</template>
<script>
export default {
    name: 'customTitle',
    props: {
        // 标题
        title: {
            type: String,
            required: true
        }
        // 宽
        // width: {
        //     type: Number,
        //     required: true
        // }
    }
};
</script>
<style scoped lang="less">
.basic-title {
    display:flex;
    align-items:center;
    width: 100%;
    height: 38px;
    background: #f2f2f2;

    .line {
        margin-left:10px;
        border-width: 0px;
        width: 5px;
        height: 29px;
        background:#409EFF;
    }
    .text{
        margin-left:7px;
        font-size:14px;
        color:#333333;
    }
}
</style>

