<template>
  <div class="shop-list">
    <div class="shop-item" v-for="(v, i) in list" :key="i">
      <div class="name">{{ v.name }}</div>
      <div class="msg">
        <!-- //主体 -->
        <div>{{ v.information }}</div>
        <!-- 税号： -->
        <div>{{ v.companyTaxNumber }}</div>
        <div v-if="v.shopPhone">电话：{{ v.shopPhone }}</div>
        <!-- <div v-if="v.addressDetail">门店地址：{{ v.addressDetail }}</div> -->
      </div>
      <div class="bot">
        <el-button type="primary" plain @click="enterBtn(v)"
          >进入店铺</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list"],
  methods: {
    enterBtn(item) {
      this.$emit("change", item);
    }
  }
};
</script>
<style scoped lang="less">
.shop-list {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, 310px);
  gap: 20px;

  .shop-item {
    width: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: left;
    background: #f2f2f2;
    padding: 20px;
    color: #333;

    div {
      margin: 10px 0;
      text-align: center;
    }

    .name {
      font-size: 18px;
      font-weight: 700;
    }

    .msg {
      width: 100%;
      // text-align: left;
      font-size: 14px;
      word-wrap: break-word;
    }

    .bot {
      display: flex;
      justify-content: space-between;

      .el-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
      }
    }
  }
}
</style>
