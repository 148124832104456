import dynamicRoutes from "@/router/dynamicRoutes";
import Layout from "@/views/layout/index.vue";
import router from "@/router/index";
import store from "@/store";
import EmptyPage from "@/views/empty_router/index.vue";

/**
 * 合并路由信息，将递归路由和一维路由合并成一份路由配置。
 * @param routes - 递归型路由数组
 * @param d - 一维路由数组
 * @returns {Array} - 合并后的路由数组
 */
const mergeRoutes = (routes, d) => {
  return routes.map(route => {
    const t = d.find(e => e.name === route.name);
    const newRoute = {
      ...route,
      ...(t ? t : { component: EmptyPage })
    };
    if (route.children && route.children.length > 0) {
      newRoute.children = mergeRoutes(route.children, d);
    }
    return newRoute;
  });
};

const routeAddTopSigns = routes => {
  return routes.map(e => ({ ...e, ...{ top: true } }));
};

/**
 * 路由扁平化
 * @param {*} routes - 多维路由数组
 * @param {String} p - 内部循环使用的path
 * @returns {Array} - 一维数组路由
 */
const flattenRoutes = (routes, p = "") => {
  return routes.reduce((flattenedRoutes, route) => {
    const { component, name, children, path: routePath, ...rest } = route;

    // 计算当前路由的路径
    const path = p ? `${p}/${routePath}` : routePath;

    // 将当前路由添加到扁平化数组中
    const newRoute = { component, name, path, ...rest };
    const newFlattenedRoutes = [...flattenedRoutes, newRoute];

    if (children && children.length > 0) {
      // 递归处理嵌套的子路由，并更新子路由的路径
      const nestedRoutes = flattenRoutes(children, path);

      // 将子路由添加到扁平化数组中
      return [...newFlattenedRoutes, ...nestedRoutes];
    }

    return newFlattenedRoutes;
  }, []);
};

/**
 * 使用此方法传入后台的路由数据，动态生成路由
 * @param {*} r - 后台返回的具有层级的路由
 * @param {Object} vueRouter
 * @returns {Array}
 */
const updateRoutes = (r, vueRouter = router) => {
  const originMenu = routeAddTopSigns(r);
  store.commit("loginData/originMenu", originMenu);
  const mergedRoutes = mergeRoutes(r, dynamicRoutes);
  vueRouter.addRoute({
    path: "/",
    name: "",
    component: Layout,
    redirect: "/company",
    children: [...flattenRoutes(mergedRoutes)]
  });
};

export { updateRoutes };
