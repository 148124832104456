// 提现记录表格配置
export const outTableColumns = [
    { label: '流水号', prop: 'name2', sortable: true },
    { label: '所属店铺', prop: 'name3', sortable: true },
    { label: '提现金额', prop: 'name4', sortable: true },
    { label: '提现申请时间', prop: 'name5', sortable: true },
    { label: '操作人', prop: 'name6', sortable: true },
    {
        label: '开票状态', type: 'select', style: 'selectBackgroundColor', prop: 'status', sortable: true,
        options: [
            { label: '已提现', value: '1', color: '#3CA929' },
            { label: '提现失败', value: '2', color: '#F16161' },
            { label: '提现中', value: '3', color: '#118DFF' }
        ]
    }

];
// 充值记录表格配置
export const investTableColumns = [
    { label: '流水号', prop: 'name2', sortable: true },
    { label: '所属店铺', prop: 'name3', sortable: true },
    { label: '充值金额', prop: 'name4', sortable: true },
    { label: '充值时间', prop: 'name5', sortable: true },
    { label: '操作人', prop: 'name6', sortable: true },
    {
        label: '开票状态', type: 'select', style: 'selectBackgroundColor', prop: 'status', sortable: true,
        options: [
            { label: '已充值', value: '1', color: '#3CA929' },
            { label: '充值失败', value: '2', color: '#F16161' },
            { label: '充值中', value: '3', color: '#118DFF' }
        ]
    }

];