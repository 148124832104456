<script>
import { qrCodeInfo } from "@/api/invoicing";
import qrcode from "qrcode";
import avatar from "@/views/layout/aside/components/avatar.vue";
export default {
  components: {
    avatar
  },
  data() {
    return {
      companyName: "",
      shopName: "",
      shopId: null,
      popoverVisible: false, // 二维码弹出框
      kpr: "",
      isMask: true, // 二维码遮罩层--默认开启
      isRetrieve: false
    };
  },
  props: {
    // 选择公司页为true，其他页为false
    isCompanyNav: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSidebarNavCollapse() {
      return this.$store.state.loginData.isSidebarNavCollapse;
    },
    faceStatus() {
      return this.$store.state.loginData.faceStatus;
    },
    userName() {
      return this.$store.state.loginData.userName || "用户";
    }
  },
  created() {
    this.companyName = this.$store.state.loginData.basicShopInfo.companyName;
    this.shopName = this.$store.state.loginData.shopInfo.name;
    this.shopId = this.$store.state.loginData.shopInfo.id;
    this.kpr = this.$store.state.loginData.shopInfo.kpr;
    this.isMask = true;
    if (this.faceStatus) {
      qrCodeInfo({ shopId: this.shopId })
        .then(res => {
          if (res.code === 1000) {
            let codeInfo = res.data;

            qrcode.toCanvas(this.$refs.canvas, codeInfo, err => {
              if (err) return err;
            });
            if (codeInfo.length > 20) {
              this.isMask = false;
            }
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    changeStoreBtn() {
      this.$confirm("是否切换店铺?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$store.commit("loginData/resetMenu");
          this.$router.push("/company");
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消"
          // });
        });
    },
    handleCommandHelp(command) {
      this.$message("click on item " + command);
    },
    /**
     * 个人设置
     */
    toPersonalSettings() {
      this.$router.push("/personal-settings-norouter");
    },
    /**
     * 退出登陆
     */
    logout() {
      this.$store.commit("loginData/logout");
    },
    // 重新获取税务App二维码
    retrieve() {
      this.isRetrieve = true;
      qrCodeInfo({ shopId: this.shopId })
        .then(res => {
          if (res.code === 1000) {
            let codeInfo = res.data;

            qrcode.toCanvas(this.$refs.canvas, codeInfo, err => {
              if (err) return err;
            });
            if (codeInfo.length > 20) {
              this.isMask = false;
            }
          }
          this.isRetrieve = false;
        })
        .catch(() => {
          this.isRetrieve = false;
        });
    },
    // 认证关闭弹出框
    attestation() {
      this.popoverVisible = false;
    }
  }
};
</script>

<template>
  <nav>
    <div class="nav-left">
      <div
        :class="[
          'nav-left_name',
          { 'nav-left_name_collapse': isSidebarNavCollapse }
        ]"
      >
        <img src="../../../assets/images/layout/icon.png" alt="数票票" />
      </div>
      <div class="nav-left_info">运营平台</div>
    </div>
    <div class="nav-right" v-if="!isCompanyNav">
      <div class="nav-right">
        <!-- <div class="nav-right-wrap">
          <i class="el-icon-office-building" />
          <span class="nav-right-wrap_text">集团管理</span>
        </div>
        <div class="nav-right-wrap">
          <i class="el-icon-s-promotion" />
          <span class="nav-right-wrap_text">联系我们</span>
        </div>
        <div class="nav-right-wrap">
          <i class="el-icon-s-custom" />
          <el-dropdown @command="handleCommandHelp">
            <span class="nav-right-wrap_text">支持与帮助</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">建议反馈</el-dropdown-item>
              <el-dropdown-item command="b">操作手册</el-dropdown-item>
              <el-dropdown-item command="c">安装包下载</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
        <div class="nav-right-wrap">
          <i class="el-icon-mobile" />
          <el-popover
            placement="bottom"
            width="200"
            v-model="popoverVisible"
            content="税务APP二维码"
          >
            <div class="popover-style">
              <span
                style="font-size: 14px; padding-bottom: 10px; font-weight: bold"
                >开票人：{{ kpr }}</span
              >
              <span style="font-size: 14px">请开票员</span>
              <span style="font-size: 14px">使用税务APP扫码人脸认证</span>
              <div class="popover-style-code">
                <div v-if="isMask" class="mask-style"></div>
                <canvas id="qrcode" ref="canvas" height="140px" width="140px" />
              </div>
              <div style="display: flex; justify-content: space-around">
                <el-button
                  size="small"
                  style="margin-top: 10px"
                  :loading="isRetrieve"
                  @click="retrieve"
                  >重新获取</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  style="margin-top: 10px"
                  @click="attestation"
                  >认证成功</el-button
                >
              </div>
            </div>
            <span
              class="nav-right-wrap_text"
              slot="reference"
              style="font-weight: bold"
              >税务APP二维码</span
            >
          </el-popover>
        </div>
        <div class="nav-right-wrap" @click="changeStoreBtn()">
          <i class="el-icon-s-shop" />
          <span class="nav-right-wrap_text"
            >{{ companyName }}-{{ shopName }} ></span
          >
        </div>
      </div>
    </div>
    <avatar style="padding: 8px 12px" v-else :isCompanyNav="isCompanyNav" />
  </nav>
</template>

<style scoped lang="less">
nav {
  background: #118dff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  .nav-left {
    display: flex;
    align-items: center;

    .nav-left_name {
      width: 220px;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      white-space: nowrap;

      img {
        width: 120px;
        height: auto;
        display: block;
      }
    }

    .nav-left_name_collapse {
      width: 66px !important;
      font-size: 26px !important;
      img {
        width: 60px;
        height: auto;
        display: block;
      }
    }

    .nav-left_info {
      font-size: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #fff;
      font-weight: bold;
      padding-left: 16px;
      white-space: nowrap;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-size: 14px;

    .nav-right-wrap {
      display: flex;
      align-items: center;
      margin-left: 39px;
      cursor: pointer;

      .nav-right-wrap_text {
        color: #fff;
        margin-left: 3.5px;
        white-space: nowrap;
      }
    }
  }
}

.popover-style {
  height: 260px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /deep/ .popover-style-code {
    position: relative;
    margin-top: 5px;
    width: 140px;
    height: 140px;
    background-image: url("../../../assets/images/invoicing/qrcode.png");
    background-size: contain;

    #qrcode {
      width: 100% !important;
      height: 100% !important;
    }

    .mask-style {
      position: absolute;
      width: 140px;
      height: 140px;
      top: 0;
      left: 0;
      background: radial-gradient(rgba(244, 244, 244, 0.9), #ffffff);
    }
  }
}
</style>
