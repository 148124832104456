export default {
  name: "wuxResizeableDatePicker",
  label: "日期选择器",
  icon: "el-icon-date",
  type: 1,
  panelValue: "",
  schemas: [
    {
      schemaLabel: "字段标识",
      schemaKey: "key",
      schemaType: "text",
      schemaValidator: (rule, value, callback) => {
        callback(
          /^$|^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(value)
            ? void 0
            : new Error("不符合命名规范!")
        );
      }
    },
    {
      schemaLabel: "是否必填",
      schemaKey: "schemaRequired",
      schemaType: "switch",
      schemaRequired: true,
      props: {
        activeColor: "#13ce66",
        inactiveColor: "#ff4949"
      }
    },
    {
      schemaLabel: "宽度",
      schemaKey: "width",
      schemaType: "number",
      props: {
        min: 10
      }
    },
    {
      schemaLabel: "控件名称",
      schemaKey: "schemaLabel",
      schemaType: "text"
    },
    {
      schemaLabel: "schemaType",
      schemaKey: "schemaType",
      schemaType: "text",
      hidden: true
    }
  ],
  panelConfigurations: {
    key: "",
    schemaRequired: false,
    width: 145,
    schemaLabel: "日期选择器",
    schemaType: "datePicker"
  }
};
